import React, { useMemo } from "react";
import { useApi, useMount, useRouter } from "hooks";
import locale from "localization";
import VoucherSkeleton from "./voucher-skeleton";
import { CampaignStatus, Path } from "enums";
import { EditRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { formatAmount, formatDate, formatNumber } from "utils";
import DateTime from "enums/datetime";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherType,
  prettifyVoucherTypeSetting,
} from "utils/pretty.utils";
import { getVoucherV2 } from "apis/campaign-program.api";
import { Grid } from "@material-ui/core";
import { PopOverList, Text } from "components/commons";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";

const ViewVoucher = () => {
  const history = useHistory();
  const { query = {} } = useRouter();
  const { id: voucherCampaignId } = query;

  const {
    request,
    result = {},
    loading,
  } = useApi({
    api: getVoucherV2,
    pageError: true,
  });

  useMount(async () => {
    request({
      voucherCampaignId,
    });
  });

  const data = useMemo(() => {
    const {
      campaignName,
      shortDescription,
      longDescription,
      campaignImage,
      voucherType,
      voucherCampaignGroup,
      voucherPrefix,
      startDate,
      endDate,
      minimumSpend,
      voucherQuantity,
      quantity,
      voucherAmount,
      uploadCSV,
      claimMethod,
      voucherSubType,
      voucherClaims,
      discountValueType,
      discountValue,
      discountCap,
      voucherStations,
      voucherValue: voucherValueData,
      billedTo,
      remarks,
    } = result || {};
    const isAutoSeed = voucherSubType === VoucherTypeSetting.AutoSeed;
    const showRecipients = isAutoSeed;
    const isMultiClaimGeneric = voucherSubType === VoucherTypeSetting.MultiClaimGeneric;
    const showVoucherValue =
      !isMultiClaimGeneric && !isAutoSeed && voucherType === VoucherType.PesoVoucherCode;

    const obj = {
      campaignName,
      shortDescription,
      longDescription,
      campaignImage,
      voucherType:
        `${prettifyVoucherType(voucherType)} ${
          voucherSubType ? `(${prettifyVoucherTypeSetting(voucherSubType)})` : ""
        }`.trim() || null,
      campaignGroup: prettifyVoucherCampaignGroup(voucherCampaignGroup),
      voucherPrefix,
      campaignDuration: `${formatDate(startDate, DateTime.A)} to ${formatDate(endDate)}`,
      minimumSpend: formatAmount(minimumSpend),
      voucherQuantity: formatNumber(voucherQuantity, 0),
      voucherValueMultiClaim: voucherValueData,
      claimLimit: formatNumber(voucherQuantity, 0),
      recipients: !showRecipients ? [] : voucherClaims,
      quantity,
      voucherValue: showVoucherValue ? voucherClaims : [],
      voucherAmount,
      uploadCSV,
      discountCap: formatAmount(discountCap),
      claimMethod: claimMethod ? prettifyVoucherClaimMethod(claimMethod) : null,
      voucherDiscountValue: `${
        discountValueType === "fixed" ? formatAmount(discountValue) : formatNumber(discountValue, 0)
      }${discountValueType === "percentage" ? "%" : ""}`,
      requiredReferenceStation: voucherStations,
      billedTo,
      remarks,
    };
    const object = {};
    Object.keys(obj).forEach((keyName) => {
      object[keyName] = {
        visible: true,
        value: obj[keyName],
      };
    });
    return object;
  }, [result]);
  const {
    campaignName = {},
    shortDescription = {},
    longDescription = {},
    campaignImage = {},
    voucherType = {},
    campaignGroup = {},
    voucherPrefix = {},
    campaignDuration = {},
    voucherValue = {},
    claimMethod = {},
    recipients = {},
    voucherDiscountValue = {},
    // voucherAmount = {},
    // uploadCSV = {},
    claimLimit = {},
    voucherValueMultiClaim = {},
    // voucherDiscountValue = {},
    discountCap = {},
    minimumSpend = {},
    requiredReferenceStation = {},
    voucherQuantity = {},
    // quantity = {},
    billedTo = {},
    billedToOthers = {},
    remarks = {},
  } = data || {};
  console.log(voucherValueMultiClaim);
  const isVoucherPesoDiscount = result?.voucherType === VoucherType.PesoDiscount;
  const isOneTimeClaim = result?.voucherSubType === VoucherTypeSetting.OneTimeClaim;
  const showDiscountCap = result?.discountValueType === "percentage";
  const isMultiClaimGeneric = result?.voucherSubType === VoucherTypeSetting.MultiClaimGeneric;
  return (
    <div>
      <VoucherSkeleton
        loading={loading}
        intro={
          result?.status !== CampaignStatus.Cancelled
            ? {
                actionSecondaryText: locale.edit,
                actionSecondaryStartIcon: <EditRounded />,
                actionSecondaryOnClick: () => {
                  history.push(Path.EditVoucherCampaignId(voucherCampaignId));
                },
              }
            : null
        }
        title={locale.voucherCampaignDetails}
        fields={{
          campaignDuration: {
            ...campaignDuration,
            label: `${locale.campaignDuration} (${locale.validityOfCodes})`,
          },
          shortDescription: {
            ...shortDescription,
            label: locale.shortDescription,
          },
          longDescription: {
            ...longDescription,
            label: locale.longDescription,
          },
          campaignName: {
            ...campaignName,
            label: locale.campaignName,
          },
          voucherDiscountValue: {
            ...voucherDiscountValue,
            visible: result?.voucherType === VoucherType.PesoDiscount,
            label: locale.voucherDiscountValue,
          },
          minimumSpend: {
            ...minimumSpend,
            visible: result?.voucherType === VoucherType.PesoDiscount,
            label: locale.minimumSpend,
          },
          claimLimit: {
            ...claimLimit,
            label: locale.claimLimit,
            visible: result?.voucherSubType === VoucherTypeSetting.MultiClaimGeneric,
          },
          campaignImage: {
            ...campaignImage,
            label: locale.campaignImage,
            content: campaignImage.value ? (
              <div
                style={{
                  background: "#f5f6fa",
                  minHeight: "35px",
                  borderRadius: "40px",
                  color: "#753bbd",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  width: "fit-content",
                }}
              >
                {campaignImage.value}
              </div>
            ) : (
              ""
            ),
          },
          voucherType: {
            ...voucherType,
            label: locale.voucherType,
          },
          discountCap: {
            ...discountCap,
            label: locale.discountCap,
            visible: showDiscountCap,
          },
          campaignGroup: {
            ...campaignGroup,
            label: locale.campaignGroup,
          },
          voucherPrefix: {
            ...voucherPrefix,
            label: locale.voucherPrefix,
          },
          claimMethod: {
            ...claimMethod,
            label: locale.claimMethod,
          },
          voucherValueMultiClaim: {
            label: locale.voucherValue,
            content: formatAmount(voucherValueMultiClaim.value),
            visible: isMultiClaimGeneric,
          },
          voucherQuantity: {
            ...voucherQuantity,
            label: locale.voucherQuantity,
            visible: isVoucherPesoDiscount && isOneTimeClaim,
          },
          recipients: {
            ...recipients,
            label: locale.recipients,
            visible: recipients.value?.length,
            content: (
              <div
                style={{
                  padding: "10px 10px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    sm={isVoucherPesoDiscount ? "6" : "4"}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Text subtitle>{locale.priceLocqAccount}</Text>
                  </Grid>
                  {!isVoucherPesoDiscount && (
                    <Grid
                      sm="4"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <Text subtitle>{locale.voucherValue}</Text>
                    </Grid>
                  )}

                  <Grid
                    sm={isVoucherPesoDiscount ? "6" : "4"}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Text subtitle>{locale.quantity}</Text>
                  </Grid>
                  {recipients.value?.map((item) => {
                    console.log("11", voucherType.value);
                    const obj = isVoucherPesoDiscount
                      ? ["mobileNumber", "quantity"]
                      : ["mobileNumber", "voucherValue", "quantity"];
                    return obj.map((column, columnKey) => {
                      return (
                        <Grid sm={isVoucherPesoDiscount ? "6" : "4"} key={columnKey}>
                          {item[column]}
                        </Grid>
                      );
                    });
                  })}
                </Grid>
              </div>
            ),
          },
          requiredReferenceStation: {
            ...requiredReferenceStation,
            visible: isVoucherPesoDiscount,
            label: locale.requiredReferenceStation,
            content:
              requiredReferenceStation.value?.length === 1 ? (
                <Text
                  strong
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  {requiredReferenceStation.value?.[0]?.stationName}
                </Text>
              ) : requiredReferenceStation.value?.length ? (
                <div
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <PopOverList
                    list={[
                      <Text strong sm>
                        Reference Station
                      </Text>,
                      ...requiredReferenceStation.value?.map(({ stationName }) => stationName),
                    ]}
                  >
                    <Text
                      strong
                      underline
                    >{`${requiredReferenceStation.value?.length} Stations`}</Text>
                  </PopOverList>
                </div>
              ) : (
                <Text
                  strong
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  All Stations
                </Text>
              ),
          },
          voucherValue: {
            ...voucherValue,
            visible: voucherValue.value?.length,
            label: locale.voucherValue,
            content: (
              <div
                style={{
                  padding: "10px 10px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    sm="6"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Text subtitle>{locale.amount}</Text>
                  </Grid>
                  <Grid
                    sm="6"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Text subtitle>{locale.quantity}</Text>
                  </Grid>

                  {voucherValue.value?.map((item) => {
                    return [
                      ["voucherValue", formatAmount],
                      ["quantity", formatNumber],
                    ].map((column, columnKey) => {
                      const [key, formatter] = column;
                      return (
                        <Grid sm="6" key={columnKey}>
                          {formatter(item[key])}
                        </Grid>
                      );
                    });
                  })}
                </Grid>
              </div>
            ),
          },
          billedTo: {
            ...billedTo,
            label: locale.billedTo,
          },
          billedToOthers: {
            ...billedToOthers,
          },
          remarks: {
            ...remarks,
            label: locale.creatorsNotes,
          },
        }}
      />
    </div>
  );
};

export default ViewVoucher;
