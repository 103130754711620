import React from "react";
import { DateRange, Field, SearchField, Filter, Button } from "components/commons";
import locale from "localization";
import styles from "./pricing-changes-filter.module.scss";

const PricingChangesFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  resetFilter,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
          close={resetFilter}
        >
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Field
              labelPosition={"left"}
              className={styles.date}
              childrenClassName={styles.fieldContent}
              label={locale.periodCovered}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(name, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.businessNameBusinessIDReferenceStation}
            value={searchKey}
            restrict={false}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default PricingChangesFilter;
