import React from "react";

import { Button, PopOverMenu, Title } from "components/commons";

import locale from "localization";

import styles from "./view-premium-payout.module.scss";
import { useExport } from "hooks";
import { generatePricingReport, getPremiumPayout } from "apis";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./view-premium-payout.mapper";
import { viewPremiumPayoutColumns } from "./view-premium-payout-column";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { viewPremiumPayoutFilterState } from "./view-premium-payout-filter.state";

const ViewPremiumPayoutTable = ({ month, year }) => {
  const exportFile = useExport({
    api: generatePricingReport,
    reportType: "consolidated-premium-payout",
    mappedFilterState: { month, year },
  });

  const exportPremiumReport = useExport({
    api: generatePricingReport,
    reportType: "redemptions-settlement",
  });

  const { table } = useDataTable({
    api: {
      api: getPremiumPayout,
      params: {
        month,
        year,
        page: 1,
        perPage: 999,
      },
    },
    filter: { initialState: viewPremiumPayoutFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "premiumPayouts",
      mapper: (premiumPayouts) =>
        mapDataToList({
          exportPremiumReport,
          premiumPayouts,
        }),
      columns: viewPremiumPayoutColumns,
    },
  });

  return (
    <>
      <div className={styles.reportContainer}>
        <Title black xsmall>
          {locale.report}
        </Title>
        <PopOverMenu
          options={[
            {
              content: locale.asExcel,
              onClick: () => exportFile.exportReport({ fileType: "xlsx" }),
            },
            {
              content: locale.asPdf,
              onClick: () => exportFile.exportReport({ fileType: "pdf" }),
            },
          ]}
        >
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            loading={exportFile?.exporting}
          >
            {locale.exportConsolidated}
          </Button>
        </PopOverMenu>
      </div>
      <DataTableV2 {...table} hidePagination />
    </>
  );
};

export default ViewPremiumPayoutTable;
