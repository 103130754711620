import React from "react";
import { useHistory } from "react-router-dom";

import { getPremiumPayoutByDate, generatePricingReport } from "apis";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { premiumPayoutFilterState } from "./premium-payout-filter.state";
import { mapFilterToRequest, mapDataToList } from "./premium-payout.mapper";
import { premiumPayoutColumns } from "./premium-payout-column";
import PremiumPayoutFilter from "./premium-payout-filter";
import PremiumPayoutList from "./premium-payout.list";
import styles from "./premium-payout.module.scss";
import { useExport } from "hooks";

const PremiumPayoutModule = () => {
  const history = useHistory();

  const { filter, search, table } = useDataTable({
    api: { api: getPremiumPayoutByDate },
    filter: { initialState: premiumPayoutFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "premiumPayouts",
      mapper: (premiumPayouts) =>
        mapDataToList({
          premiumPayouts,
          history,
        }),
      columns: premiumPayoutColumns,
    },
  });

  const exportFile = useExport({
    api: generatePricingReport,
    reportType: "premium-payout",
    mappedFilterState: { ...filter.mappedFilterState },
  });

  return (
    <>
      <Intro title={locale.monthlyPremiumPayment} subtitle={locale.viewAndTrackMonthlyPremium} />
      <PremiumPayoutFilter {...filter} {...search} {...exportFile} />
      <div className={styles.table}>
        <PremiumPayoutList table={table} />
      </div>
    </>
  );
};

export default PremiumPayoutModule;
