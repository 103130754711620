import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getStations } from "apis";
import { useApi } from "hooks";
import { locale } from "localization/en";

const SelectRedemptionStation = ({ placeholder = "", disableAllOption, params = {}, ...props }) => {
  const {
    request,
    loading,
    result = { stations: [], count: 0 },
  } = useApi({
    api: getStations,
    params,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        perPage: 1000,
        page: 1,
        ...params,
      });
    },
    [request]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      isMultiple
      total={result.count}
      loading={loading}
      allSelectedText={locale.allRedemptionStations}
      allLabel={locale.redemptionStations}
      selectAllLabel={locale.selectAll}
      formatRequest={(result) => {
        const { stations = [] } = result || {};
        if (stations && stations.length > 0) {
          const newStations = stations;

          return newStations.map((d) => {
            return {
              label: d.name,
              value: d.stationId,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
      {...(!disableAllOption ? {
        allOption: [{
          label: "All",
          value: null
        }
      ]} : [])}
    />
  );
};

export default SelectRedemptionStation;
