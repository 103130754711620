import React, { useMemo } from "react";
import { useApi, useMount, useRouter } from "hooks";
import {
  clearStationAlert,
  clearStationHistory,
  getAccountStationById,
} from "apis/account-stations";
import locale from "localization";
import { capitalize, capitalizeWord, formatAmount } from "utils/text.utils";
import moment from "moment/moment";
import StationSkeleton from "./station-skeleton";
import ProductName from "components/commons/product/product-name";
import { DateTime, Path, PLBStatus, PLCStatus } from "enums";
import Products from "components/commons/product/products";
import { EditRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import StationHistoryField from "enums/station-history";
import AlertIconTooltip from "components/commons/alert/alert-icon-tooltip";
import { Button, Text } from "components/commons";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import PlatformType from "enums/platform-type";
import { prettifyPlatformType } from "utils/pretty.utils";

const ViewStation = () => {
  const history = useHistory();
  const { query = {} } = useRouter();
  const { id: stationId } = query;
  const [clearedStationHistory, setClearedStationHistory] = useState([]);

  const {
    request,
    result = {},
    loading,
  } = useApi({
    api: getAccountStationById,
    pageError: true,
  });

  const stationAlertRequest = useApi({
    api: clearStationAlert,
    handleOwnError: true,
  });

  const stationHistoryRequest = useApi({
    api: clearStationHistory,
    handleOwnError: true,
  });

  const handleClearStationHistory = useCallback(
    (stationHistoryId) => {
      setClearedStationHistory([...clearedStationHistory, stationHistoryId]);
      stationHistoryRequest.request({ stationHistoryId });
    },
    [clearedStationHistory, stationHistoryRequest]
  );

  useMount(async () => {
    request({
      stationId,
    });
  });

  const data = useMemo(() => {
    const {
      city,
      province,
      stationCode,
      stationType,
      name,
      businessName,
      companyGroup,
      stationProduct = {},
      address,
      latitude,
      longitude,
      depot = {},
      email,
      rtmName,
      stationFleetDiscount = [],
      rtmEmail,
      mobileNumber,
      bankAccountName,
      bankAccountNumber,
      bankName,
      settlementEmail,
      opensAt,
      closesAt,
      stationHistory,
      plcStatus,
      plbStatus,
    } = result || {};
    const { diesel, gas91, gas95, gas97 } = stationProduct;
    const { depotCode, name: depotLocation } = depot || {};

    const getHistoryKeyAndLabel = (history) => {
      const obj = {
        [StationHistoryField.BusinessName]: {
          label: capitalize(locale.businessName),
          key: "businessName",
        },
        [StationHistoryField.StationType]: {
          label: capitalize(locale.stationType),
          key: "stationType",
        },
        [StationHistoryField.CompanyGroup]: {
          label: capitalize(locale.companyGroup),
          key: "companyGroup",
        },
        [StationHistoryField.DepotCode]: {
          label: capitalize(locale.depotCode),
          key: "depotId",
        },
        [StationHistoryField.Diesel]: {
          label: capitalize("diesel"),
          key: "diesel",
          oldValue: history.oldValue === "0" ? "unavailable" : "available",
          newValue: history.newValue === "0" ? "unavailable" : "available",
        },
        [StationHistoryField.Gas91]: {
          label: capitalize("gas91"),
          key: "gas91",
          oldValue: history.oldValue === "0" ? "unavailable" : "available",
          newValue: history.newValue === "0" ? "unavailable" : "available",
        },
        [StationHistoryField.Gas95]: {
          label: capitalize("gas95"),
          key: "gas95",
          oldValue: history.oldValue === "0" ? "unavailable" : "available",
          newValue: history.newValue === "0" ? "unavailable" : "available",
        },
        [StationHistoryField.Gas97]: {
          label: capitalize("gas97"),
          key: "gas97",
          oldValue: history.oldValue === "0" ? "unavailable" : "available",
          newValue: history.newValue === "0" ? "unavailable" : "available",
        },
      };
      if (obj[history.fieldName]) {
        const historyField = {
          ...history,
          ...obj[history.fieldName],
        };
        return {
          ...historyField,
          text: (
            <locale.Populate
              text={locale.changedFromNtoNInPriceTool}
              items={[
                <b>{historyField.label}</b>,
                <b>{historyField.oldValue}</b>,
                <b>{historyField.newValue}</b>,
              ]}
            />
          ),
        };
      }
      return null;
    };
    const history = {};
    const productHistory = [];

    stationHistory?.forEach((h) => {
      if (!clearedStationHistory.includes(h.stationHistoryId)) {
        const hField = getHistoryKeyAndLabel(h);
        if (["diesel", "gas91", "gas95", "gas97"].includes(hField?.key)) {
          productHistory.push(hField);
        } else if (hField) {
          history[hField.key] = hField;
        }
      }
    });
    const isPlcActive = plcStatus === PLCStatus.Active;
    const isPlbActive = plbStatus === PLBStatus.Active;

    const plcOnboarded = Boolean(PLCStatus.NotOnboarded !== plcStatus && plcStatus);
    const plbOnboarded = Boolean(PLBStatus.NotOnboarded !== plbStatus && plbStatus);

    // const showPLCInformation = plcOnboarded;
    const showSettlementInfo =
      (plcOnboarded || plbOnboarded) && ["CODO", "DODO"].includes(stationType);

    const showMaxDiscounts = plbOnboarded && ["WIDOX", "DODOX"].includes(stationType);
    const gasDiscount = {
      visible: showMaxDiscounts,
    };

    const visibleFields = {
      plbMaxDiscount: showMaxDiscounts,
      bankAccountName: showSettlementInfo,
      bankAccountNumber: showSettlementInfo,
      nameOfBank: showSettlementInfo,
      settlementEmail: showSettlementInfo,
      mobileNumberReceiving: plcOnboarded,
    };

    stationFleetDiscount.forEach((discount) => {
      gasDiscount[discount.productCode] = formatAmount(discount.maxDiscount || 0);
    });

    const obj = {
      stationCode: stationCode?.toString(),
      stationName: name,
      businessName,
      companyGroup,
      stationType,
      depotLocation,
      depotId: depotCode,
      productAvailability: {
        diesel,
        gas91,
        gas95,
        gas97,
        productHistory,
      },
      fullAddress: address,
      province: capitalizeWord(province),
      city: capitalizeWord(city),
      openingTime: moment(opensAt, "HH:mm:ss").format(DateTime.L),
      closingTime: moment(closesAt, "HH:mm:ss").format(DateTime.L),
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      emailAddress: email,
      rtmName,
      rtmEmailAddress: rtmEmail,
      mobileNumberReceiving: mobileNumber?.trim()
        ? `+63${
            mobileNumber[0] === "0"
              ? mobileNumber.toString().substring(1, 11)
              : mobileNumber.toString()
          }`
        : "-",
      bankAccountName,
      bankAccountNumber,
      nameOfBank: bankName,
      settlementEmail,
      plbMaxDiscount: gasDiscount,
      initialOnboarding: `${isPlcActive || isPlbActive ? "Onboard Now," : "Not onboarded"} ${
        isPlcActive && isPlbActive
          ? prettifyPlatformType(PlatformType.PLCandPLB)
          : isPlbActive
          ? prettifyPlatformType(PlatformType.PLB)
          : isPlcActive
          ? prettifyPlatformType(PlatformType.PLC)
          : ""
      }`,
    };

    const object = {};
    Object.keys(obj).forEach((keyName) => {
      object[keyName] = {
        visible: visibleFields.hasOwnProperty(keyName) ? visibleFields[keyName] : true,
        value: obj[keyName],
        content: history[keyName] ? (
          <div className="flex items-center">
            <Text
              strong
              style={{
                marginRight: "10px",
              }}
            >
              {obj[keyName]}
            </Text>
            <AlertIconTooltip yellow right>
              {history[keyName]?.text}
              <Button
                textButton
                onClick={() => {
                  handleClearStationHistory(history[keyName].stationHistoryId);
                }}
              >
                {locale.okGotIt}
              </Button>
            </AlertIconTooltip>
          </div>
        ) : null,
      };
    });
    return object;
  }, [result, handleClearStationHistory, clearedStationHistory]);

  useEffect(() => {
    const { stationHistory = [] } = result || {};
    if (
      stationHistory.length &&
      clearedStationHistory.length &&
      clearedStationHistory.length === stationHistory.length
    ) {
      stationAlertRequest.request({ stationId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, clearedStationHistory]);

  const {
    stationCode = {},
    stationName = {},
    businessName = {},
    companyGroup = {},
    stationType = {},
    depotLocation = {},
    depotId = {},
    productAvailability = {},
    fullAddress = {},
    province = {},
    city = {},
    openingTime = {},
    closingTime = {},
    latitude = {},
    longitude = {},
    emailAddress = {},
    rtmName = {},
    rtmEmailAddress = {},
    initialOnboarding = {},
    mobileNumberReceiving = {},
    plbMaxDiscount = {},
    bankAccountName = {},
    bankAccountNumber = {},
    nameOfBank = {},
    settlementEmail = {},
  } = data || {};

  return (
    <div>
      <StationSkeleton
        loading={loading}
        intro={{
          actionSecondaryText: locale.edit,
          actionSecondaryStartIcon: <EditRounded />,
          actionSecondaryOnClick: () => {
            history.push(Path.EditStationId(stationId));
          },
        }}
        title={locale.stationDetails}
        fields={{
          stationCode: {
            ...stationCode,
            label: locale.stationCode,
          },
          stationName: {
            ...stationName,
            label: locale.stationName,
          },
          businessName: { ...businessName, label: locale.businessName },
          companyGroup: { ...companyGroup, label: locale.companyGroup },
          stationType: { ...stationType, label: locale.stationType },
          depotLocation: { ...depotLocation, label: locale.depotLocation },
          depotId: { ...depotId, label: locale.depotId },
          productAvailability: {
            ...productAvailability,
            label: locale.productAvailability,
            content: (
              <div className="flex items-center">
                <Products
                  horizontal
                  {...productAvailability.value}
                  textClassName="bold"
                  style={{
                    marginRight: "10px",
                  }}
                />
                {Boolean(productAvailability.value?.productHistory?.length) && (
                  <AlertIconTooltip yellow right>
                    <div>
                      {productAvailability.value?.productHistory?.map((history, key) => {
                        return (
                          <div
                            style={{
                              marginBottom: "5px",
                            }}
                            key={key}
                          >
                            {history.text}
                          </div>
                        );
                      })}
                      <Button
                        textButton
                        onClick={() => {
                          const historyIds = productAvailability.value?.productHistory.map(
                            ({ stationHistoryId }) => stationHistoryId
                          );
                          setClearedStationHistory([...clearedStationHistory, ...historyIds]);
                          historyIds.forEach(({ stationHistoryId }) => {
                            stationHistoryRequest.request({ stationHistoryId });
                          });
                        }}
                      >
                        {locale.okGotIt}
                      </Button>
                    </div>
                  </AlertIconTooltip>
                )}
              </div>
            ),
          },
          fullAddress: {
            ...fullAddress,
            label: locale.fullAddress,
          },
          province: {
            ...province,
            label: locale.province,
          },
          city: {
            ...city,
            label: `${locale.city}/${locale.municipality}`,
          },
          openingTime: {
            ...openingTime,
            label: locale.stationOpeningTime,
          },
          closingTime: {
            ...closingTime,
            label: locale.stationClosingTime,
          },
          latitude: {
            ...latitude,
            label: locale.latitude,
          },
          longitude: {
            ...longitude,
            label: locale.longitude,
          },
          emailAddress: {
            ...emailAddress,
            label: locale.stationEmailAddress,
          },
          rtmName: {
            ...rtmName,
            label: locale.rtmName,
          },
          rtmEmailAddress: {
            ...rtmEmailAddress,
            label: locale.rtmEmailAddress,
          },
          initialOnboarding: {
            ...initialOnboarding,
            label: locale.initialOnboarding,
            visible: true,
          },
          mobileNumberReceiving: {
            ...mobileNumberReceiving,
            label: locale.mobileNumberReceiving,
          },
          plbMaxDiscount: {
            ...plbMaxDiscount,
            label: locale.plbMaxDiscount,
            content: (
              <div className="flex items-center">
                {[
                  {
                    product: "diesel",
                    value: plbMaxDiscount.value.diesel || "-",
                  },
                  {
                    product: "gas91",
                    value: plbMaxDiscount.value.gas91 || "-",
                  },
                  {
                    product: "gas95",
                    value: plbMaxDiscount.value.gas95 || "-",
                  },
                  {
                    product: "gas97",
                    value: plbMaxDiscount.value.gas97 || "-",
                  },
                ].map((value, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <ProductName product={value.product} />
                      <div
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        {value.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
          },
          bankAccountName: {
            ...bankAccountName,
            label: locale.bankAccountName,
          },
          bankAccountNumber: {
            ...bankAccountNumber,
            label: locale.bankAccountNumber,
          },
          nameOfBank: {
            ...nameOfBank,
            label: locale.nameOfBank,
          },
          settlementEmail: {
            ...settlementEmail,
            label: locale.settlementEmail,
          },
        }}
      />
    </div>
  );
};

export default ViewStation;
