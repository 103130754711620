import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Intro } from "components/commons";
import { ConfirmModal, SuccessModal } from "components/modals";

import { generateReportAdmin, getFleetAccounts, updateFleetAccount } from "apis";
import { AccountStatus } from "enums";
// Path
import { useApi, useModal } from "hooks";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { handleRequest } from "utils";

import { accountListColumns } from "./account-list-columns";
import AccountListFilter from "./account-list-filter";
import { accountListFilterState } from "./account-list-filter.state";
import { mapFilterToRequest, mapDataToList } from "./account-list.mapper";
import AccountList from "./account-list.list";
import useExport from "hooks/useExport";
import { UserContext } from "contexts";

const AccountListModule = (props) => {
  const history = useHistory();
  const confirmModal = useModal();
  const { close } = confirmModal;

  const { sessionId: connectionId } = useContext(UserContext)

  const { request: updateStatusRequest, loading } = useApi({
    api: updateFleetAccount,
    handleOwnError: {
      badrequest: true,
    },
  });

  const handleUpdateStatus = (params, fleetName) => {
    const { status } = params;
    const activation = status === AccountStatus.Active;
    confirmModal.show({
      title: activation ? locale.reactivateFleetAccount : locale.deactivateFleetAccount,
      content: activation ? (
        <locale.Populate text={locale.reactivateFleetAccountContent} items={[<b>{fleetName}</b>]} />
      ) : (
        <locale.Populate text={locale.deactivateFleetAccountContent} items={[<b>{fleetName}</b>]} />
      ),
      secondary: {
        text: locale.Cancel,
      },
      primary: {
        text: activation ? locale.yesReactivateIt : locale.yesDeactivateIt,
        onClick: async () => {
          await submitUpdateStatus(params);
          close();
        },
      },
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getFleetAccounts },
    filter: { initialState: accountListFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "fleets",
      mapper: (fleets) =>
        mapDataToList({
          fleets,
          history,
          handleUpdateStatus,
        }),
      columns: accountListColumns,
      tableProps: { history, handleUpdateStatus },
    },
  });

  const submitUpdateStatus = async (params) => {
    await handleRequest(async () => {
      await updateStatusRequest({
        ...params,
      });
      table.fetch({});
    });
  };

  const exportReport = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "fleet-account",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      fleetStatus: filter?.mappedFilterState?.status,
      searchKey: search?.searchKey,
      connectionId
    },
  });

  return (
    <>
      <ConfirmModal loading={loading} {...confirmModal} />
      <SuccessModal {...exportReport?.successModalComponent} />
      <Intro
        // actionText={locale.addFleetAccount}
        // actionOnClick={() => {
        //   history.push(Path.AddFleetAccount);
        // }}
        title={locale.fleetAccountList}
        subtitle={locale.viewTrackFleetAccounts}
      />
      <AccountListFilter {...filter} {...search} {...exportReport} />
      <AccountList table={table} />
    </>
  );
};

export default AccountListModule;
