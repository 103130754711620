import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Pill, PopOverList, PopOverMenu, Text } from "components/commons";
import locale from "localization";
import { dateTimeFormat, formatAmount, formatVolume, prettifySettlementStatus } from "utils";
import { SettlementStatus, Path } from "enums";

import styles from "./settlement-history.module.scss";

export const mapDataToList = ({ settlements, handleCancelSettlement, history }) => {
  const {
    settlementOilcoId,
    totalLiter,
    remarks,
    oilcoSettlementStatus,
    updatedAt,
    totalMopsMovementW1,
    totalOilcoSettlementReceivablePayable,
    totalPriceMovementDifferentialW1,
    totalMopsRemarks,
    totalAdditionalOilcoShoulderedAdjustment,
    totalNationwideMovementSinceBuy,
    totalOilcoShoulderedStationSwitch,
    totalOilcoShoulderedDiscountAtBuy,
    totalRedemptionStationSpecificMovementSinceBuy,
    totalOilcoSettlementReceivablePayableRemarks,
  } = settlements;

  // receivablePayableToHc,
  // totalMopsMovement,
  // totalPriceMovementDifferentialReceivablePayableToOilco,
  // totalStationSpecificMovementReceivablePayableToOilco,
  // totalOilcoShoulderedStationSwitch,
  // totalOilcoShoulderedDiscountAtBuy

  return {
    oilcoSettlementId: (
      <>
        <Text>{settlementOilcoId}</Text>
      </>
    ),
    totalLiterRedemption: formatVolume(totalLiter),
    totalOilcoSettlement: formatAmount(totalOilcoSettlementReceivablePayable),
    totalOilcoSettlementReceivablePayableRemarks:
      oilcoSettlementStatus === SettlementStatus.Processed &&
      totalOilcoSettlementReceivablePayableRemarks ? (
        <PopOverList list={[totalOilcoSettlementReceivablePayableRemarks]}>
          <Button link>{locale.view}</Button>
        </PopOverList>
      ) : (
        "--"
      ),
    totalMOPSMovement: (
      <>
        <Text>{formatAmount(totalMopsMovementW1)}</Text>
        <Text className={styles.subBusinessId}>
          {formatAmount(totalNationwideMovementSinceBuy)}
        </Text>
      </>
    ),
    totalMopsRemarks:
      oilcoSettlementStatus === SettlementStatus.Processed && totalMopsRemarks ? (
        <PopOverList list={[totalMopsRemarks]}>
          <Button link>{locale.view}</Button>
        </PopOverList>
      ) : (
        "--"
      ),
    totalPriceMovementDifferential: formatAmount(totalPriceMovementDifferentialW1),
    totalRedSMovement: formatAmount(totalRedemptionStationSpecificMovementSinceBuy),
    totalAdditionalOilcoShoulderedAdjustment: formatAmount(
      totalAdditionalOilcoShoulderedAdjustment
    ),
    totalOilcoShoulderedDiscount: formatAmount(totalOilcoShoulderedDiscountAtBuy),
    totalOilcoShoulderedStation: formatAmount(totalOilcoShoulderedStationSwitch),
    oilcoSettlementStatus: (
      <>
        <Pill
          sky={
            oilcoSettlementStatus === SettlementStatus.ForProcessing ||
            oilcoSettlementStatus === SettlementStatus.ForSettlement
          }
          grass={
            oilcoSettlementStatus === SettlementStatus.Processed ||
            oilcoSettlementStatus === SettlementStatus.Settled
          }
        >
          {prettifySettlementStatus(oilcoSettlementStatus)}
        </Pill>
        <Text className={styles.subBusinessId}>{updatedAt ? dateTimeFormat(updatedAt) : "--"}</Text>
      </>
    ),
    oilcoRemarks:
      oilcoSettlementStatus === SettlementStatus.Processed && remarks ? (
        <PopOverList list={[remarks]}>
          <Button link>{locale.view}</Button>
        </PopOverList>
      ) : (
        "--"
      ),
    action: (
      <PopOverMenu
        options={[
          {
            content: locale.viewDetails,
            onClick: () => history.push(Path.ViewSettlementHistoryById(settlementOilcoId)),
          },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { oilcoSettlementStatuses, ...fs } = filterState;
  return {
    ...fs,
    // discountVoucherStatuses: discountVoucherStatuses.join(","),
    oilcoSettlementStatuses: oilcoSettlementStatuses.join(","),
  };
};
