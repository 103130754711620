import VoucherDiscountType from "enums/voucher-discount-type";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";
import locale from "localization";
import moment from "moment";
import { formatNumber, isAmountValid, isNumeric, parseNumber } from "utils";

export const voucherValueState = {
  amount: {
    value: "",
    validation: ({ value }) => {
      return isAmountValid(value);
    },
  },
  quantity: {
    value: "",
    validation: ({ value }) => {
      return isNumeric(value);
    },
  },
};

export const voucherAmountState = {
  mobileNumber: {
    value: "",
    validation: ({ value = "" }) => {
      return isNumeric(value);
    },
  },
  amount: {
    value: "",
    validation: ({ value }) => {
      return isAmountValid(value);
    },
  },
  quantity: {
    value: "",
    validation: ({ value }) => {
      return isNumeric(value);
    },
  },
};

const hasErrorInMinMax = (value, min = 1, max = 100000) => {
  let hasError = "";
  const val = parseNumber(value);
  if (value && val < min) {
    hasError = `Minimum of 1`;
  }
  if (value && val > max) {
    hasError = `Maximum of 100,000`;
  }
  return Boolean(hasError) || !val;
};

export const validateVoucherValue = (value = []) => {
  let error = false;
  value.forEach((item = {}) => {
    const quantity = !hasErrorInMinMax(item.quantity?.value);
    const amount = !hasErrorInMinMax(item.amount?.value);
    if (!quantity || !amount) {
      error = true;
    }
  });
  return error;
};

export const validateVoucherAmount = (value, { isAmountRequired }) => {
  let error = false;
  value.forEach((item) => {
    const quantity = !hasErrorInMinMax(item.quantity?.value);
    const amount = isAmountRequired ? !hasErrorInMinMax(item.amount?.value) : true;
    const mobileNumber =
      isNumeric(item.mobileNumber.value) &&
      item.mobileNumber.value.length === 10 &&
      item.mobileNumber.value[0] === "9" &&
      item.mobileNumber.errorType !== "not-found";
    if (!quantity || !amount || !mobileNumber) {
      error = true;
    }
  });
  return error;
};

export const voucherFormInitialState = {
  form: {
    campaignName: {
      label: locale.campaignName,
      placeholder: "This appears as the voucher name in the App.",
      validations: {
        isRequired: true,
        custom: [
          (value, field) => {
            const { errorType } = field;
            const error = errorType === "VC1008";
            return {
              error,
              message: error ? "This campaign name already exists. Please enter a new one." : "",
            };
          },
        ],
      },
      maxLength: 150,
    },
    shortDescription: {
      label: locale.shortDescription,
      placeholder: "This appears in the short description in the App.",
      validations: {
        isRequired: true,
      },
      maxLength: 50,
      showCounter: true,
    },
    longDescription: {
      label: locale.longDescription,
      placeholder: "This appears in the voucher details in the App.",
      validations: {
        isRequired: true,
      },
      multiline: true,
      rowsMax: "5",
      maxLength: 500,
      showCounter: true,
    },
    campaignImage: {
      label: locale.campaignImage,
      validations: {
        custom: [
          (value = [], { errorType }) => {
            const errors = {
              "invalid-file": "File type not accepted. Please upload a .jpg or .png file.",
              "invalid-dimension":
                "File does not meet the required dimensions. Please change to 1:1 aspect ratio.",
              "invalid-size": `File size exceeds maximum limit. Maximum allowable size is 2MB.`,
            };
            return {
              error: Boolean(errors[errorType]),
              message: errors[errorType] || "",
            };
          },
        ],
      },
    },
    voucherType: {
      label: locale.voucherType,
      validations: {
        isRequired: true,
      },
    },
    voucherTypeSetting: {
      label: "",
      value: VoucherTypeSetting.OneTimeClaim,
    },
    campaignGroup: {
      label: locale.campaignGroup,
      validations: {
        isRequired: true,
      },
    },
    voucherPrefix: {
      label: locale.voucherPrefix,
      maxLength: 10,
      type: "input",
      validations: {
        isRequired: true,
        isAlphaNumeric: true,
        isNoSpace: true,
        custom: [
          (value, field) => {
            const { errorType } = field;
            const error = errorType === "VC1000";
            return {
              error,
              message: error ? "This voucher prefix already exists. Please enter a new one." : "",
            };
          },
        ],
      },
    },
    recipients: {
      label: locale.recipients,
      validations: {
        isRequired: false,
      },
      visible: false,
    },
    campaignDuration: {
      label: `${locale.campaignDuration} (${locale.validityOfCodes})`,
      validations: {
        isRequired: true,
        custom: [
          (value) => {
            const { startDate, endDate } = value || {};
            const error = !startDate || !endDate;
            return {
              error,
              message: error ? "This field is required" : "",
            };
          },
        ],
      },
      value: {
        startDate: null,
        endDate: null,
      },
      minDate: new Date(moment().startOf("day")),
    },
    voucherValue: {
      label: locale.voucherValue,
      value: [voucherValueState],
      canAdd: true,
      type: "input",
      validations: {
        isRequired: false,

        custom: [
          (value = [], field) => {
            if (!field.validations.isRequired) {
              return {
                error: false,
                message: "",
              };
            }
            const error = validateVoucherValue(value);

            return {
              error,
              message: "",
            };
          },
        ],
      },
    },
    voucherAmount: {
      label: locale.voucherValue,
      value: [voucherAmountState],
      canAdd: true,
      visible: false,
      validations: {
        isRequired: false,
        custom: [
          (value = [], field, form) => {
            if (!field.validations.isRequired) {
              return {
                error: false,
                message: "",
              };
            }
            const { voucherType } = form;
            const error = validateVoucherAmount(value, {
              isAmountRequired: voucherType.value === VoucherType.PesoVoucherCode,
            });
            return {
              error,
              message: "",
            };
          },
        ],
      },
    },
    claimMethod: {
      label: locale.claimMethod,
      value: "",
      validations: {
        isRequired: true,
      },
    },
    uploadCSV: {
      label: " ",
      visible: false,
      validations: {
        isRequired: false,
      },
    },
    voucherValueMultiClaim: {
      value: "",
      visible: false,
      label: locale.voucherValue,
      type: "input",
      validations: {
        isNumber: true,
        minimum: 1,
        maximum: 100000,
      },
    },
    claimLimit: {
      value: "",

      label: locale.claimLimit,
      visible: false,
      type: "input",
      validations: {
        isNumeric: true,
        minimum: 1,
        maximum: 5000,
      },
    },
    voucherDiscountValue: {
      label: locale.voucherDiscountValue,
      visible: false,
    },
    discountValue: {
      type: "input",
      validations: {
        isNumber: true,
        minimum: 1,
        custom: [
          (value, field, form = {}) => {
            const { voucherDiscountValue = {} } = form || {};
            let error = false;
            const isPercentage = VoucherDiscountType.Percent === voucherDiscountValue.value;
            if (isPercentage) {
              error = value ? parseNumber(value) > 100 : false;
            } else {
              error = value ? parseNumber(value) > 100000 : false;
            }
            return {
              error,
              message: error ? `Maximum of ${formatNumber(isPercentage ? 100 : 100000, 0)}` : "",
            };
          },
        ],
      },
    },
    discountCap: {
      label: locale.discountCap,
      type: "input",
      visible: false,
      validations: {
        isNumber: true,
        minimum: 1,
        maximum: 100000,
      },
    },
    minimumSpend: {
      label: locale.minimumSpend,
      type: "input",
      visible: false,
      validations: {
        isNumber: true,
        minimum: 1,
        maximum: 100000,
        custom: [
          (value, field, form) => {
            const v = parseNumber(value);
            const { discountValue, voucherDiscountValue } = form || {};
            const discount = parseNumber(discountValue.value);
            // const discountCapValue = parseNumber(discountCap.value);
            const error =
              VoucherDiscountType.Specific === voucherDiscountValue.value ? v < discount : false;
            return {
              error,
              message: error
                ? "Minimum Spend should not be less than the Peso Amount Discount"
                : "",
            };
          },
        ],
      },
    },
    requiredReferenceStation: {
      visible: false,
      label: locale.requiredReferenceStation,
      allLabel: locale.referenceStation,
      isAll: true,
      isMultiple: true,
      value: [],
      placeholder: locale.searchOrSelect,
      disableAllOption: true,
      selectAllLabel: locale.all,
      allSelectedText: `All ${locale.referenceStation}`,
    },
    voucherQuantity: {
      visible: false,
      label: locale.voucherQuantity,
      type: "input",
      validations: {
        isNumeric: true,
      },
    },
    quantity: {
      visible: false,
      label: locale.quantity,
      type: "input",
      validations: {
        isNumeric: true,
      },
    },
    billedTo: {
      label: locale.billedTo,
      validations: {
        isRequired: true,
      },
    },
    billedToOthers: {
      validations: {
        isRequired: false,
      },
    },
    remarks: {
      label: locale.creatorsNotes,
      placeholder: "",
      multiline: true,
      rowsMax: "5",
      maxLength: 500,
      showCounter: true,
    },
  },
};
