import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import FleetAccountsPage from "./fleet-accounts.page";
import ViewFleetAccountModule from "modules/fleet-accounts/details/account-view/account-view.module";

import PanelButtons from "components/commons/panel-button/panel-buttons";
import ViewFleetPricingModule from "modules/fleet-accounts/pricing/pricing.module";
import AddFleetAccountModule from "modules/fleet-accounts/details/account-add/account-add.module";
import EditFleetAccountModule from "modules/fleet-accounts/details/account-edit/account-edit.module";

import locale from "localization";

import { BackLink } from "components/commons";
import { useRouter } from "hooks";
import StationPage from "./station.page";

const ViewFleetPage = () => {
  const { query } = useRouter();
  return (
    <>
      <BackLink text={locale.fleetAccountList} path={Path.FleetAccounts} />
      <PanelButtons
        items={[
          {
            text: "Fleet Account Details",
            path: Path.ViewFleetAccountById(query?.id),
          },
          {
            text: "Pricing Details",
            path: Path.ViewFleetPricingById(query?.id),
          },
        ]}
      />
      <div style={{ marginTop: 60 }}>
        <Switch>
          <Route path={Path.ViewFleetAccount} component={ViewFleetAccountModule} />
          <Route path={Path.EditFleetAccount} component={EditFleetAccountModule} />
          <Route path={Path.ViewFleetPricing} component={ViewFleetPricingModule} />
          <Redirect to={Path.ViewFleetAccount} />
        </Switch>
      </div>
    </>
  );
};

// ViewFleetPage

const AccountsPage = () => {
  // ViewFleetDetails
  return (
    <Switch>
      <Route exact path={Path.FleetAccounts} component={FleetAccountsPage} />
      <Route exact path={Path.AddFleetAccount} component={AddFleetAccountModule} />
      <Route path={Path.ViewFleetDetails} component={ViewFleetPage} />
      <Route path={Path.Station} component={StationPage} />
      <Redirect to={Path.FleetAccounts} />
    </Switch>
  );
};

export default AccountsPage;
