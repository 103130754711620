import {
  clearStationAlert,
  getAccountStations,
  getAlertStationCount,
  updateStationStatus,
} from "apis/account-stations";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import React from "react";
import { stationFilterState } from "./station-filter.state";
import { stationColumn } from "./station.column";
import StationList from "./station.list";
import { mapDataToList, mapFilterToRequest } from "./station.mapper";
import locale from "localization";
import StationFilter from "./station-filter";
import { useApi, useModal } from "hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Path, PLBStatus, PLCStatus } from "enums";
import { useCallback } from "react";
import OnboardPLCModal from "./onboard-plc-modal";
import OnboardPLBModal from "./onboard-plb-modal";
import DeactivatePLCModal from "./deactivate-plc-station";
import { ConfirmModal } from "components/modals";
import SendActivationModal from "./send-activation-modal";
import SendWelcomeEmail from "./send-welcome-email";

const Station = () => {
  const history = useHistory();
  const alertRequestCount = useApi({
    api: getAlertStationCount,
    handleOwnError: true,
  });
  const updateStatusRequest = useApi({
    api: updateStationStatus,
  });

  const clearStationAlertRequest = useApi({
    api: clearStationAlert,
  });

  const plcOnboardModal = useModal();
  const plbOnboardModal = useModal();
  const deactivatePLCModal = useModal();
  const actionModal = useModal();
  const sendActivationModal = useModal();
  const sendWelcomeModal = useModal();

  const onboardInPLC = useCallback(
    ({ stationId, stationType, ...props }) => {
      plcOnboardModal.show({ stationId, stationType, ...props });
    },
    [plcOnboardModal]
  );

  const onboardInPLB = useCallback(
    ({ stationId, stationType, ...props }) => {
      plbOnboardModal.show({ stationId, stationType, ...props });
    },
    [plbOnboardModal]
  );

  const deactivatePLC = useCallback(
    (stationId) => {
      deactivatePLCModal.show({ stationId });
    },
    [deactivatePLCModal]
  );

  const deactivatePLB = useCallback(
    (stationId) => {
      actionModal.show({
        title: locale.deactivateStationInPLB,
        content: locale.onceDeactivatedPLB,
        primary: {
          text: locale.yesDeactivateIt,
          onClick: async () => {
            await updateStatusRequest.request({
              stationId,
              plbStatus: PLBStatus.Deactivated,
            });
            actionModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            actionModal.close();
          },
        },
      });
    },
    [actionModal, updateStatusRequest]
  );

  const sendActivationEmail = useCallback(
    ({ stationId, stationName }) => {
      sendActivationModal.show({
        stationId,
        stationName,
      });
    },
    [sendActivationModal]
  );

  const sendWelcomeEmail = useCallback(
    ({ stationId, stationName }) => {
      sendWelcomeModal.show({
        stationId,
        stationName,
      });
    },
    [sendWelcomeModal]
  );

  const activatePLB = useCallback(
    (stationId) => {
      actionModal.show({
        title: locale.reactivateStationInPLB,
        content: locale.reactivatingStationPLB,
        primary: {
          text: locale.yesReactivateIt,
          onClick: async () => {
            await updateStatusRequest.request({
              stationId,
              plbStatus: PLBStatus.Active,
            });
            actionModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            actionModal.close();
          },
        },
      });
    },
    [actionModal, updateStatusRequest]
  );

  const hideStationPLC = useCallback(
    (stationId) => {
      actionModal.show({
        title: locale.hideStationInPLC,
        content: locale.hidingStation,
        primary: {
          text: locale.yesHideIt,
          onClick: async () => {
            await updateStatusRequest.request({
              stationId,
              plcStatus: PLCStatus.Hidden,
            });
            actionModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            actionModal.close();
          },
        },
      });
    },
    [actionModal, updateStatusRequest]
  );

  const showStationPLC = useCallback(
    (stationId) => {
      actionModal.show({
        title: locale.unhideStation,
        content: locale.unhideDesc,
        primary: {
          text: locale.yesUnhideIt,
          onClick: async () => {
            await updateStatusRequest.request({
              stationId,
              plcStatus: PLCStatus.Active,
            });
            actionModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            actionModal.close();
          },
        },
      });
    },
    [actionModal, updateStatusRequest]
  );

  const activatePLC = useCallback(
    (stationId) => {
      actionModal.show({
        title: locale.reactivateStationInPLC,
        content: locale.reactivateDescription,
        primary: {
          text: locale.yesReactivateIt,
          onClick: async () => {
            await updateStatusRequest.request({
              stationId,
              plcStatus: PLCStatus.Active,
            });
            actionModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            actionModal.close();
          },
        },
      });
    },
    [actionModal, updateStatusRequest]
  );

  const { table, filter, search } = useDataTable({
    api: {
      api: async (param) => {
        alertRequestCount.request();
        return await getAccountStations(param);
      },
    },
    filter: { initialState: stationFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "stations",
      mapper: (data) =>
        mapDataToList({
          data,
          history,
          onboardInPLC,
          onboardInPLB,
          deactivatePLC,
          activatePLC,
          deactivatePLB,
          activatePLB,
          hideStationPLC,
          showStationPLC,
          sendActivationEmail,
          sendWelcomeEmail,
          clearStationAlert: {
            ...clearStationAlertRequest,
            request: async (param) => {
              await clearStationAlertRequest.request(param);
              table.fetch({
                newPageState: applyPage({ page: 1, isReverse: false }),
                apiOptions: {
                  useLoader: false,
                },
              });
            },
          },
        }),
      columns: stationColumn,
    },
  });

  const { fetch, applyPage } = table;
  const refreshPage = useCallback(() => {
    fetch({
      newPageState: applyPage({ page: 1, isReverse: false }),
      apiOptions: {
        useLoader: false,
      },
    });
  }, [fetch, applyPage]);

  return (
    <div>
      <Intro
        title={locale.stationAccountList}
        subtitle={locale.viewAndTrackStationAccounts}
        actionText={locale.addStation}
        actionOnClick={() => {
          history.push(Path.AddStation);
        }}
      />
      <StationFilter {...filter} {...search} alertRequestCount={alertRequestCount} />
      <StationList {...table} />
      <SendActivationModal {...sendActivationModal} refresh={refreshPage} />
      <SendWelcomeEmail {...sendWelcomeModal} refresh={refreshPage} />
      <OnboardPLCModal {...plcOnboardModal} refresh={refreshPage} />
      <OnboardPLBModal {...plbOnboardModal} refresh={refreshPage} />
      <DeactivatePLCModal {...deactivatePLCModal} refresh={refreshPage} />
      <ConfirmModal
        {...actionModal}
        loading={updateStatusRequest.loading}
        primary={{
          ...actionModal.primary,
          onClick: async () => {
            await actionModal.primary.onClick();
            refreshPage();
          },
        }}
      />
    </div>
  );
};

export default Station;
