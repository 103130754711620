import { Autocomplete } from "components/commons";
import React from "react";
import { prettifyPlatformType } from "utils/pretty.utils";
import PlatformType from "enums/platform-type";

const SelectPlatformType = ({ onChange, name, value, className, multiple, hasAll }) => {
  console.log({
    onChange,
    name,
    value,
    className,
    multiple,
    hasAll,
  });
  return (
    <Autocomplete
      value={value}
      name={name}
      placeholder={"Select"}
      multiple={multiple}
      hasAll={hasAll}
      options={[PlatformType.PLC, PlatformType.PLB, PlatformType.PLCandPLB].map((val) => ({
        label: prettifyPlatformType(val),
        value: val,
      }))}
      onChange={(_, { value }) => onChange(name, { value })}
    />
  );
};

export default SelectPlatformType;
