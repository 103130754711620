import React from "react";
import List from "../list/list";
import { NavLink } from "react-router-dom";
import styles from "./panel-button.module.scss";

const PanelButtons = ({ items, className }) => {
  return (
    <List
      className={className}
      components={items.map((item) => {
        return (
          <NavLink
            to={item.path}
            path={item.path}
            className={styles.navLink}
            activeClassName={styles.activeNavLink}
          >
            {item.text}
          </NavLink>
        );
      })}
    />
  );
};

export default PanelButtons;
