import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu } from "components/commons";
import locale from "localization";
import { formatAmount, formatVolume } from "utils";

import { DateTime } from "enums";

import styles from "../premium-payout.module.scss";

export const mapDataToList = ({ premiumPayouts, exportPremiumReport }) => {
  const { station, totalLiterRedemption, totalPremiumPayout, totalPayWithPeso, periodDate } =
    premiumPayouts;

  const newPeriodDate = periodDate?.split("-") ?? [];

  return {
    redemptionStation: station?.name,
    totalLiters: formatVolume(totalLiterRedemption),
    totalPayPeso: formatAmount(totalPayWithPeso),
    totalPremium: formatAmount(totalPremiumPayout),
    action: (
      <PopOverMenu
        options={[
          {
            content: locale.downloadReport,
            onClick: () =>
              exportPremiumReport?.exportReport({
                fileType: "pdf",
                redemptionStationId: station?.stationId,
                startDate: new Date(
                  moment(`${newPeriodDate[0]} ${newPeriodDate[1]}`)
                    .startOf("month")
                    .format(DateTime.C)
                ),
                endDate: new Date(
                  moment(`${newPeriodDate[0]} ${newPeriodDate[1]}`)
                    .endOf("month")
                    .format(DateTime.D)
                ),
                settlementType: "premium-payout",
              }),
          },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
