import { Field, Radio, Text, TextField, TimePicker } from "components/commons";
import Products from "components/commons/product/products";
import { InputAmount } from "components/field";
import SelectCities from "components/field/dropdown/select-cities";
import SelectPlatformType from "components/field/dropdown/select-platform-type";
import SelectProvince from "components/field/dropdown/select-province";
import OnboardStatus from "enums/onboard-status";
import locale from "localization";
import React from "react";
import { prettifyOnboardStatus } from "utils/pretty.utils";

import StationSkeleton from "./station-skeleton";

const StationForm = ({ loading, title, form = {}, intro }) => {
  const { fields } = form;
  const {
    stationCode = {},
    stationName = {},
    businessName = {},
    companyGroup = {},
    stationType = {},
    depotLocation = {},
    depotId = {},
    productAvailability = {},
    fullAddress = {},
    province = {},
    city = {},
    openingTime = {},
    closingTime = {},
    latitude = {},
    longitude = {},
    emailAddress = {},
    rtmName = {},
    rtmEmailAddress = {},
    initialOnboarding = {},
    mobileNumberReceiving = {},
    plbMaxDiscount = {},
    bankAccountName = {},
    bankAccountNumber = {},
    nameOfBank = {},
    settlementEmail = {},
    platformType = {},
    diesel = {},
    gas91 = {},
    gas95 = {},
    gas97 = {},
  } = fields || {};
  return (
    <div>
      <StationSkeleton
        intro={intro}
        title={title}
        loading={loading}
        fields={{
          stationCode: {
            ...stationCode,
            content: stationCode.content || <TextField {...stationCode} />,
          },
          stationName: {
            ...stationName,
            content: stationName.content || (
              <div>
                <TextField {...stationName} disabled={loading} />
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.formatStationName}
                </Text>
              </div>
            ),
          },
          businessName: { ...businessName },
          companyGroup: { ...companyGroup },
          stationType: { ...stationType },
          depotLocation: { ...depotLocation },
          depotId: { ...depotId },
          productAvailability: {
            ...productAvailability,
            content: <Products horizontal {...productAvailability.value} textClassName="bold" />,
          },
          fullAddress: {
            ...fullAddress,
            content: fullAddress.content || <TextField {...fullAddress} disabled={loading} />,
          },
          province: {
            ...province,
            content: province.content || (
              <SelectProvince
                {...province}
                disabled={loading}
                onChange={(name, value) => {
                  if (!value.isDirty) {
                    form.modifyForm({
                      province: value,
                    });
                  } else {
                    form.modifyForm({
                      province: value,
                      city: {
                        value: "",
                      },
                    });
                  }
                }}
              />
            ),
          },
          city: {
            ...city,
            content: city.content || (
              <SelectCities {...city} province={province.key} disabled={loading} />
            ),
          },
          openingTime: {
            ...openingTime,
            content: openingTime.content || (
              <div
                style={{
                  width: "160px",
                }}
              >
                <TimePicker {...openingTime} />
              </div>
            ),
          },
          closingTime: {
            ...closingTime,
            content: closingTime.content || (
              <div
                style={{
                  width: "160px",
                }}
              >
                <TimePicker {...closingTime} />
              </div>
            ),
          },
          latitude: {
            ...latitude,
            content: latitude.content || (
              <div>
                <TextField {...latitude} disabled={loading} />
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.populate(locale.pleaseInputAtleast5Decimal, ["14.34098"])}
                </Text>
              </div>
            ),
          },
          longitude: {
            ...longitude,
            content: longitude.content || (
              <div>
                <TextField {...longitude} disabled={loading} />{" "}
                <Text
                  subtitle
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {locale.populate(locale.pleaseInputAtleast5Decimal, ["120.20032"])}
                </Text>
              </div>
            ),
          },
          emailAddress: {
            ...emailAddress,
            content: emailAddress.content || <TextField {...emailAddress} disabled={loading} />,
          },
          rtmName: {
            ...rtmName,
            content: rtmName.content || <TextField {...rtmName} disabled={loading} />,
          },
          rtmEmailAddress: {
            ...rtmEmailAddress,
            content: rtmEmailAddress.content || (
              <TextField {...rtmEmailAddress} disabled={loading} />
            ),
          },
          initialOnboarding: {
            ...initialOnboarding,
            content: initialOnboarding.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...initialOnboarding}
                options={[
                  {
                    value: OnboardStatus.OnBoardLater,
                    text: prettifyOnboardStatus(OnboardStatus.OnBoardLater),
                  },
                  {
                    value: OnboardStatus.OnBoardNow,
                    text: prettifyOnboardStatus(OnboardStatus.OnBoardNow),
                    content: (
                      <div
                        style={{
                          marginLeft: "40px",
                          width: "180px",
                        }}
                      >
                        <SelectPlatformType {...platformType} />
                      </div>
                    ),
                  },
                ]}
              />
            ),
          },
          mobileNumberReceiving: {
            ...mobileNumberReceiving,
            content: mobileNumberReceiving.content || (
              <TextField
                {...mobileNumberReceiving}
                disabled={loading}
                prefix={
                  <div>
                    <Text
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      +63
                    </Text>
                  </div>
                }
              />
            ),
          },
          plbMaxDiscount: {
            ...plbMaxDiscount,
            content: plbMaxDiscount.content || (
              <div className="flex items-center">
                {[diesel, gas91, gas95, gas97].map((productField, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      <Field
                        {...productField}
                        style={{
                          margin: "0px",
                        }}
                      >
                        <div
                          style={{
                            width: "95px",
                          }}
                        >
                          <InputAmount innerLabel {...productField} />
                        </div>
                      </Field>
                    </div>
                  );
                })}
              </div>
            ),
          },
          bankAccountName: {
            ...bankAccountName,
            content: bankAccountName.content || (
              <TextField {...bankAccountName} disabled={loading} />
            ),
          },
          bankAccountNumber: {
            ...bankAccountNumber,
            content: bankAccountNumber.content || (
              <TextField {...bankAccountNumber} disabled={loading} />
            ),
          },
          nameOfBank: {
            ...nameOfBank,
            content: nameOfBank.content || <TextField {...nameOfBank} disabled={loading} />,
          },
          settlementEmail: {
            ...settlementEmail,
            content: settlementEmail.content || (
              <TextField {...settlementEmail} disabled={loading} />
            ),
          },
        }}
      />
    </div>
  );
};

export default StationForm;
