import React from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./account-view.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro } from "components/commons";
import AccountDetailsModule from "../account-details/account-details.module";

import { useRouter } from "hooks";

const ViewFleetAccountModule = (props) => {
  const { query } = useRouter();
  console.log(query);
  const history = useHistory();
  const fleetId = query?.id ?? "";

  return (
    <div>
      <div className={styles.intro}>
        <Intro
          title={locale.fleetAccountDetails}
          actionText={locale.edit}
          actionOnClick={() => {
            history.push(Path.EditFleetAccountById(fleetId));
          }}
          actionOutlined
          actionClassName={styles.primaryEditButton}
          actionStartIcon={<span className="icon-pencil" />}
        />
        <AccountDetailsModule pageMode={FormMode.View} fleetId={fleetId} />
      </div>
    </div>
  );
};

export default ViewFleetAccountModule;
