import React from "react";
import styles from "./account-edit-confirm.module.scss";
import { formatVolume, formatCurrency, formatPesoWithDecimalRange } from "utils";
import { fleetEditFormData } from "utils";
import locale from "localization";
import { Field, Title, Pill, Text } from "components/commons";

const EditConfirm = ({ ...formObject }) => {
  const { updateParams, emailAddresses, removedEmailAddresses, stationDepotName } = formObject;
  const changedFields = fleetEditFormData(updateParams, {
    emailAddresses,
    removedEmailAddresses,
    stationDepotName,
  });

  return (
    <div className={styles.container}>
      {changedFields.businessInformation && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.businessInformation}
          </Title>
          {Object.values(changedFields.businessInformation).map((bi, i) => {
            console.log(bi);

            const getRemptionValue = () => {
              if (bi.value.length) {
                return bi.value
                  .map(({ label }) => {
                    return label;
                  })
                  .sort()
                  .join(",");
              }
              return locale.allRedemptionStations;
            };

            return (
              <Field className={styles.fieldLabel} {...bi} key={i}>
                <Text className={styles.fieldText}>
                  {bi.label === "Redemption Stations" ? getRemptionValue() : bi.value}
                </Text>
              </Field>
            );
          })}
        </div>
      )}
      {changedFields.contactInformation && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.contactInformation}
          </Title>

          {Object.values(changedFields.contactInformation).map((ci, i) => (
            <Field className={styles.fieldLabel} {...ci} key={i}>
              {ci.emailAddress ? (
                <div className={styles.pillEmailAddressesContainer}>
                  {ci.value.map((obj, e) => (
                    <Pill desertstorm className={styles.emailAddressInPill} key={e}>
                      <span>{obj.emailAddress}</span>
                      <br />
                      {obj.new && <span className={styles.newStatus}>{locale.newUser}</span>}
                      {obj.resend && (
                        <span className={styles.resendStatus}>{locale.forResending}</span>
                      )}
                      {obj.removed && (
                        <span className={styles.resendStatus}>{locale.removedUser}</span>
                      )}
                    </Pill>
                  ))}
                </div>
              ) : (
                <Text className={styles.fieldText}>{ci.value}</Text>
              )}
            </Field>
          ))}
        </div>
      )}
      {changedFields.basePriceUponOnboarding && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.basePriceUponOnboarding}
          </Title>
          {Object.values(changedFields.basePriceUponOnboarding).map((bp, i) => (
            <Field className={styles.fieldLabel} {...bp} key={i}>
              <Text className={styles.fieldText}>{formatCurrency(bp.value)}</Text>
            </Field>
          ))}
        </div>
      )}
      {changedFields.agreedDiscounts && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.agreedDiscounts}
          </Title>
          {Object.values(changedFields.agreedDiscounts).map((ad, i) => (
            <Field className={styles.fieldLabel} {...ad} key={i}>
              <Text className={styles.fieldText}>{formatCurrency(ad.value)}</Text>
            </Field>
          ))}
        </div>
      )}
      {changedFields.estimateMonthlyVolume && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.estimateMonthlyVolume}
          </Title>
          {Object.values(changedFields.estimateMonthlyVolume).map((emv, i) => (
            <Field className={styles.fieldLabel} {...emv} key={i}>
              <Text className={styles.fieldText}>{formatVolume(emv.value)}</Text>
            </Field>
          ))}
        </div>
      )}
      {changedFields.existingVolume && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.existingVolume}
          </Title>
          {Object.values(changedFields.existingVolume).map((emv, i) => (
            <Field className={styles.fieldLabel} {...emv} key={i}>
              <Text className={styles.fieldText}>{formatVolume(emv.value)}</Text>
            </Field>
          ))}
        </div>
      )}
      {changedFields.sdum && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.sdum}
          </Title>
          {Object.values(changedFields.sdum).map((sd, i) => (
            <Field className={styles.fieldLabel} {...sd} key={i}>
              <Text className={styles.fieldText}>{formatCurrency(sd.value)}</Text>
            </Field>
          ))}
        </div>
      )}
      {changedFields.freight && (
        <div>
          <Title xsmall className={styles.infoTitle}>
            {locale.freight}
          </Title>
          {Object.values(changedFields.freight).map((f, i) => (
            <Field className={styles.fieldLabel} {...f} key={i}>
              <Text className={styles.fieldText}>{formatPesoWithDecimalRange(f.value, 4)}</Text>
            </Field>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditConfirm;
