import { ApiPath } from "enums";
import { Get, Post, Put, Delete } from "utils";

export const getFleets = async () => {
  const res = await Get(`${ApiPath.User}/fleet`);
  return res.data;
};

export const getUserAttribute = async ({ attributeId }) => {
  const res = await Get(`${ApiPath.UserAttribute}/${attributeId}`);
  return res.data;
};

export const getUserAttributes = async ({ ...query }) => {
  const res = await Get(`${ApiPath.UserAttributes}`, query);
  return res.data;
};

export const createUserAttribute = async ({ ...body }) => {
  const res = await Post(`${ApiPath.UserAttribute}`, body);
  return res.data;
};

export const updateUserAttribute = async ({ attributeId, ...body }) => {
  const res = await Put(`${ApiPath.UserAttribute}/${attributeId}`, body);
  return res.data;
};

export const deleteUserAttribute = async ({ attributeId }) => {
  const res = await Delete(`${ApiPath.UserAttribute}/${attributeId}`);
  return res;
};

export const assignUserCohort = async ({ userId, ...body }) => {
  const res = await Post(`${ApiPath.User}/${userId}/cohort`, body);
  return res;
};

export const importUserCohort = async ({ file }) => {
  let formData = new FormData();
  formData.append("data", file);
  const res = await Post(`${ApiPath.User}/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getUserByMobileNumber = async (body) => {
  const res = await Get(`${ApiPath.GetUserByMobileNumber}`, body);
  return res;
};

export const getUserInvite = async ({ token }) => {
  const res = await Get(`${ApiPath.UserInvite}/token/${token}`);
  return res.data;
};

export const acceptInvite = async ({ ...body }) => {
  const res = await Post(`${ApiPath.UserInvite}/accept`, body);
  return res.data;
};
