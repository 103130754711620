import { VoucherCampaignCurrency } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import VoucherStatus from "enums/voucher-status";

export const voucherUniqueFilterState = ({ location = {} }) => {
  const { state = {} } = location;
  const { search = "" } = state || {};
  return {
    startDate: null,
    endDate: null,
    searchKey: search || "",
    voucherType: "all",
    page: 1,
    perPage: 10,
    status: [
      VoucherStatus.Used,
      VoucherStatus.AvailableInApp,
      VoucherStatus.Unclaimed,
      VoucherStatus.Expired,
    ],
    type: [
      VoucherCampaignCurrency.Peso,
      VoucherCampaignCurrency.Liter,
      VoucherCampaignCurrency.Points,
    ],
    campaignCreators: {
      value: [],
      isAll: true,
    },
    campaignGroup: [
      VoucherCampaignGroup.Others,
      VoucherCampaignGroup.SPIAllowance,
      VoucherCampaignGroup.SPIAllocation,
    ],
  };
};
