import React from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapApiRequest, mapDataToList, mapFilterToRequest } from "./unique-voucher.mapper";
import { voucherUniqueFilterState } from "./unique-voucher-filter.state";
import { columns } from "./unique-vouchers-columns";

import DataTableV2 from "components/commons/data-table/data-table-v2";

import { SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { useRouter } from "hooks";
import UniqueVouchersFilter from "./unique-voucher-filter";
import { getVouchersUniqueV2 } from "apis/voucher.api";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "contexts";

const UniqueVouchersModule = () => {
  const { sessionId: connectionId } = useContext(UserContext);
  const { history } = useRouter();
  const location = useLocation();

  const { filter, search, table } = useDataTable({
    api: { api: getVouchersUniqueV2, mapper: mapApiRequest },
    filter: { initialState: voucherUniqueFilterState({ location }), mapper: mapFilterToRequest },
    table: { key: "voucherClaims", mapper: (data) => mapDataToList({ history, data }), columns },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "voucher-claim",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      statuses: filter?.mappedFilterState?.status,
      voucherCampaignGroups: filter?.mappedFilterState?.campaignGroup,
      voucherCampaignCreators: filter?.mappedFilterState?.campaignCreators,
      searchKey: search.searchKey,
      connectionId,
    },
  });

  return (
    <>
      {/* <PanelButtons
        items={[
          {
            text: locale.uniqueVouchers,
            path: Path.UniqueVouchers,
          },
        ]}
      /> */}
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          title={locale.uniqueVouchers}
          subtitle={locale.viewAllUniqueVouchers}
          // actionText={locale.createUniqueVouchers}
          // actionOnClick={() => {
          //   history.push(Path.AddUniqueVouchers);
          // }}
        />
      </div>
      <UniqueVouchersFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default UniqueVouchersModule;
