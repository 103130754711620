import {
  CampaignStatus,
  Products,
  VoucherCampaignCurrency,
  VoucherCampaignType,
  SettlementStatus,
  AuditTrailAction,
  AccountStatus,
  ReportType,
  StationStatus,
  PLCStatus,
  PLBStatus,
  FleetAccountType,
  UserStatus,
  LubeServSite,
} from "enums";
import OnboardStatus from "enums/onboard-status";
import PlatformType from "enums/platform-type";
import VoucherStatus from "enums/voucher-status";
import OilChangeType from "enums/oil-change-type";
import Role from "enums/role";
import locale from "localization";
import VoucherCampaignGroup from "enums/voucher-campaign-group";
import ClaimMethod from "enums/claim-method";
import VoucherTypeOptions from "enums/voucher-type-options";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";

export const prettifyProduct = (productCode) => {
  return (
    {
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Products.Diesel]: locale.diesel,
    }[productCode] || ""
  );
};
export const prettifyVoucherCampaignCurrency = (type) => {
  return (
    {
      [VoucherCampaignCurrency.Peso]: locale.peso,
      [VoucherCampaignCurrency.Liter]: locale.liter,
      [VoucherCampaignCurrency.Points]: locale.points,
    }[type] || ""
  );
};

export const prettifyVoucherStatus = (type) => {
  return (
    {
      [VoucherStatus.Unclaimed]: locale.unclaimed,
      [VoucherStatus.Used]: locale.used,
      [VoucherStatus.Expired]: locale.expired,
      [VoucherStatus.Available]: locale.availableInApp,
      [VoucherStatus.Void]: locale.void,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignType = (type) => {
  return (
    {
      [VoucherCampaignType.Generic]: locale.generic,
      [VoucherCampaignType.Unique]: locale.unique,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignStatus = (type) => {
  return (
    {
      [CampaignStatus.Upcoming]: locale.upcoming,
      [CampaignStatus.Ongoing]: locale.ongoing,
      [CampaignStatus.Ended]: locale.ended,
      [CampaignStatus.Cancelled]: locale.cancelled,
    }[type] || ""
  );
};

export const prettifyOilChangeType = (type) => {
  return (
    {
      [OilChangeType.dieselMonoGrade]: "Diesel Engine Oil Monograde Mineral (40)",
      [OilChangeType.dieselMultiGrade]: "Deomax Multigrade Mineral (15W-40)",
      [OilChangeType.dieselFullySynthetic]: "Extreme Mako Fully Synthetic (5W-40)",
      [OilChangeType.gasolineMonoGrade]: "Gasoline Engine Oil Monograde Mineral (40)",
      [OilChangeType.gasolineMultiGrade]: "Geo Supreme Multigrade Mineral (20W-50)",
      [OilChangeType.gasolineFullySynthetic]: "Extreme Mako Fully Synthetic (0W-40)",
    }[type] || ""
  );
};

export const prettifyLubeServSite = (lubeServSite) => {
  return (
    {
      [LubeServSite.LubeServAnabuImusCavite]: "LubeServ Anabu Imus Cavite",
      [LubeServSite.LubeServTelabastaganPampanga]: "LubeServ Telabastagan Pampanga",
    }[lubeServSite] || ""
  );
};

export const prettifySettlementStatus = (type) => {
  return (
    {
      [SettlementStatus.ForProcessing]: locale.forProcessing,
      [SettlementStatus.ForSettlement]: locale.forSettlement,
      [SettlementStatus.Processed]: locale.processed,
    }[type] || ""
  );
};

export const prettifyAccountStatus = (accountStatus) => {
  return (
    {
      [AccountStatus.Active]: locale.active,
      [AccountStatus.Pending]: locale.pending,
      [AccountStatus.Deactivated]: locale.deactivated,
      [AccountStatus.Expired]: locale.expired,
      [AccountStatus.Inactive]: locale.inactive,
    }[accountStatus] || ""
  );
};

export const prettifyAuditTrailAction = (type) => {
  return (
    {
      [AuditTrailAction.UpdateFleetDiscount]: "Update Fleet Discount",
      [AuditTrailAction.UpdateFleetRedemptionStaitons]: "Update Fleet Redemption Stations",
      [AuditTrailAction.UpdateFleetRefs]: "Update Fleet Reference Station",
      [AuditTrailAction.ApplyAccountSpecificMovement]: "Apply Account Specific Movement",
    }[type] || ""
  );
};

export const prettifyStationStatus = (type) => {
  return (
    {
      [StationStatus.ForActivation]: "For Activation",
      [StationStatus.Pending]: "Pending",
      [StationStatus.Active]: "Active",
      [StationStatus.Expired]: "Expired",
      [StationStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyOnboardStatus = (type) => {
  return (
    {
      [OnboardStatus.OnBoardLater]: locale.onboardLater,
      [OnboardStatus.OnBoardNow]: locale.onboardNow,
    }[type] || ""
  );
};

export const prettifyPLCStatus = (type) => {
  return (
    {
      [PLCStatus.NotOnboarded]: "Not Onboarded",
      [PLCStatus.Active]: "Active",
      [PLCStatus.Hidden]: "Hidden",
      [PLCStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyPLBStatus = (type) => {
  return (
    {
      [PLBStatus.NotOnboarded]: "Not Onboarded",
      [PLBStatus.Active]: "Active",
      [PLBStatus.Deactivated]: "Deactivated",
    }[type] || ""
  );
};

export const prettifyVoucherOption = (value) => {
  return (
    {
      [VoucherTypeOptions.PesoVoucherCodeOneTime]: "Peso Voucher (One-Time Claim)",
      [VoucherTypeOptions.PesoVoucherCodeAutoSeed]: "Peso Voucher (Auto-Seed)",
      [VoucherTypeOptions.PesoVoucherCodeMultiClaim]: "Peso Voucher (Multi-Claim Generic)",
      [VoucherTypeOptions.PesoDiscountCodeOneTime]: "Peso Discount (One-Time Claim)",
      [VoucherTypeOptions.PesoDiscountCodeAutoSeed]: "Peso Discount (Auto-Seed)",
      [VoucherTypeOptions.PesoDiscountCodeMultiClaim]: "Peso Discount (Multi-Claim Generic)",
    }[value] || ""
  );
};

export const prettifyVoucherType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: locale.pesoDiscountOnBuyFuelVoucherCode,
    }[value] || ""
  );
};

export const prettifyVoucherTypeSetting = (value) => {
  return (
    {
      [VoucherTypeSetting.OneTimeClaim]: "One-Time Claim",
      [VoucherTypeSetting.AutoSeed]: "Auto-Seed",
      [VoucherTypeSetting.MultiClaimGeneric]: "Multi-Claim Generic",
    }[value] || ""
  );
};

export const prettifyVoucherStatusType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: "Peso Discount",
    }[value] || ""
  );
};

export const prettifyVoucherClaimMethod = (value) => {
  return (
    {
      [ClaimMethod.AutoCashIn]: locale.autoCashIn,
      [ClaimMethod.SeedInVoucherTray]: locale.seedInVoucherTray,
    }[value] || ""
  );
};

export const prettifyVoucherCampaignGroup = (group) => {
  return (
    {
      [VoucherCampaignGroup.SPIAllocation]: "SPI Allocation",
      [VoucherCampaignGroup.SPIAllowance]: "SPI Allowance",
      [VoucherCampaignGroup.SPIObTravel]: "SPI OB Travel",
      [VoucherCampaignGroup.Others]: "Others",
    }[group] || ""
  );
};

export const prettifyPlatformType = (type) => {
  return (
    {
      [PlatformType.PLC]: "PLC Only",
      [PlatformType.PLB]: "PLB Only",
      [PlatformType.PLCandPLB]: "PLC and PLB",
    }[type] || ""
  );
};

export const prettifyReportType = (type) => {
  return (
    {
      [ReportType.CashIn]: "Cashin",
      [ReportType.BuyFuel]: "Buy Fuel",
      [ReportType.Redemption]: "Redemption",
      [ReportType.RedemptionItem]: "Redemption Item",
      [ReportType.PricingHistory]: "Pricing History",
      [ReportType.FuelCode]: "Fuel Code",
      [ReportType.FuelCodes]: "Fuel Codes",
      [ReportType.Mops]: "Mops",
      [ReportType.VoucherClaim]: "Voucher Claim",
      [ReportType.NewCustomerVoucher]: "New Customer Voucher",
      [ReportType.FleetPrices]: "Fleet Prices",
      [ReportType.PriceBuildup]: "Price Buildup",
      [ReportType.PricingChanges]: "Pricing Changes",
      [ReportType.FleetAccount]: "Fleet Account",
      [ReportType.StationAccount]: "Station Account",
      [ReportType.CustomerList]: "Customer List",
      [ReportType.VoucherCampaign]: "Voucher Campaign",
      [ReportType.DiscountCampaign]: "Discount Campaign",
      [ReportType.RfidTopup]: "Rfid Topup",
      [ReportType.Payment]: "Payment",
      [ReportType.KYCSubmission]: "KYC Submission",
      [ReportType.PriceMovement]: "Price Movement",
      [ReportType.SupplierWPP]: "Supplier WPP",
      [ReportType.Settlements]: "Settlements",
      [ReportType.LubeservBookings]: "LubeServ on Wheels",
      [ReportType.StationNegotiatedDiscount]: "DOX Negotiated Discount",
      [ReportType.LubeservOnSite]: "LubeServ on Site",
      [ReportType.LubeservOnWheels]: "LubeServ on Wheels",
    }[type] || ""
  );
};

export const prettifyFleetAccountType = (value) => {
  return (
    {
      [FleetAccountType.FleetAccount]: "Fleet Account",
      [FleetAccountType.KeyAccount]: "Key Account",
    }[value] || ""
  );
};

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyRole = (role) => {
  return (
    {
      [Role.PortalAdmin]: locale.admin,
      [Role.LubeServ]: locale.lubeServe,
      [Role.Marketing]: locale.marketing,
      [Role.Operations]: locale.operations,
      [Role.RetailPricing]: locale.retailPricing,
      [Role.Accounting]: locale.accounting,
      [Role.Finance]: locale.finance,
      [Role.Audit]: locale.audit,
    }[role] || ""
  );
};
