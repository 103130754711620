import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PaymentsModule from "../modules/payments/payments.module";
import PremiumPayoutPage from "./premium-payment.page";
import SettlementHistoryPage from "./settlement-history.page";

const PaymentsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Payments} component={PaymentsModule} />
      <Route path={Path.SettlementHistory} component={SettlementHistoryPage} />
      <Route path={Path.PremiumPayout} component={PremiumPayoutPage} />
      <Redirect to={Path.Payments} />
    </Switch>
  );
};

export default PaymentsPage;
