const VoucherStatus = {
  Unclaimed: "unclaimed",
  Used: "used",
  Expired: "expired",
  Available: "available-in-app",
  Void: "void",
  AvailableInApp: "available-in-app",
};

export default VoucherStatus;
