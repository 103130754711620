import { getVouchersUnique } from "apis/voucher.api";
import { useApi } from "hooks";
import { locale } from "localization/en";
import React, { useContext } from "react";
import VoucherList from "./voucher-list";
import {
  formatAmount,
  formatDate,
  formatName,
  formatPesoWithDecimalRange,
  prettifyVoucherCampaignCurrency,
} from "utils";
import { DateTime, VoucherCampaignCurrency } from "enums";
import { LabelAndValue, Pill } from "components/commons";
import VoucherStatus from "enums/voucher-status";
import { prettifyVoucherStatus } from "utils/pretty.utils";
import styles from "./voucher-list.module.scss";
import { generateReportAdmin } from "apis";
import { UserContext } from "contexts";

const UniqueVouchers = () => {
  const api = useApi({ api: getVouchersUnique });
  const { sessionId: connectionId } = useContext(UserContext)

  const exportApi = useApi({
    api: generateReportAdmin,
    params: {
      reportType: "voucher-claim",
      connectionId
    },
  });

  return (
    <VoucherList
      title={locale.uniqueVouchers}
      description={locale.viewAllUniqueVouchers}
      api={api}
      exportApi={exportApi}
      filters={["dateClaimed", "status"]}
      columns={[
        {
          key: "voucherCode",
          text: locale.voucherCode,
          width: "5%",
        },
        {
          key: "campaignName",
          text: locale.campaignName,
          width: "10%",
        },
        {
          key: "valueCurrency",
          text: locale.valueCurrency,
          width: "10%",
        },
        {
          key: "claimedBy",
          text: locale.claimedBy,
          width: "10%",
        },
        {
          key: "startDateEndDate",
          text: locale.startDateEndDate,
          width: "10%",
        },
        {
          key: "dateClaimedDateUsed",
          text: locale.dateClaimedDateUsed,
          width: "5%",
        },
        {
          key: "status",
          text: locale.status,
          width: "10%",
        },
      ]}
      mapResult={({ voucherClaims = [], count }) => {
        return {
          count,
          data: voucherClaims.map(
            ({ voucherCode, voucherCampaign = {}, claimedBy, status, claimedAt }) => {
              const { campaignName, voucherCurrency, voucherValue, startDate, endDate } =
                voucherCampaign;
              const map = new Map();

              const obj = {
                voucherCode,
                campaignName: <div className={styles.columnText}>{campaignName}</div>,
                valueCurrency: (
                  <LabelAndValue label={prettifyVoucherCampaignCurrency(voucherCurrency)}>
                    {voucherCurrency === VoucherCampaignCurrency.Peso
                      ? formatPesoWithDecimalRange(voucherValue)
                      : formatAmount(voucherValue)}
                  </LabelAndValue>
                ),
                claimedBy: claimedBy ? (
                  <LabelAndValue label={claimedBy?.mobileNumber}>
                    {formatName(claimedBy?.firstName, claimedBy?.lastName)}
                  </LabelAndValue>
                ) : (
                  "-"
                ),
                startDateEndDate: (
                  <LabelAndValue label={formatDate(endDate, DateTime.A)}>
                    {formatDate(startDate, DateTime.A)}
                  </LabelAndValue>
                ),
                dateClaimedDateUsed: (
                  <LabelAndValue label={claimedAt ? formatDate(claimedAt, DateTime.A) : "--"}>
                    {claimedAt ? formatDate(claimedAt, DateTime.A) : "--"}
                  </LabelAndValue>
                ),
                status: (
                  <Pill
                    cement={VoucherStatus.Expired === status}
                    cheddar={VoucherStatus.Unclaimed === status}
                    grass={VoucherStatus.Used === status}
                    sky={VoucherStatus.Available === status}
                    deepRed={VoucherStatus.Void === status}
                  >
                    {prettifyVoucherStatus(status)}
                  </Pill>
                ),
              };

              Object.keys(obj).forEach((key) => {
                map.set(key, obj[key]);
              });
              return map;
            }
          ),
        };
      }}
    />
  );
};

export default UniqueVouchers;
