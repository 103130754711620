import { Products } from "enums";
import StationType from "enums/station-type";

export const stationFilterState = () => ({
  startDate: null,
  endDate: null,
  perPage: 10,
  page: 1,
  stationType: [
    StationType.COCO,
    StationType.CODO,
    StationType.CODOX,
    StationType.COXO,
    StationType.WIBCO,
    StationType.WILCO,
    StationType.DODOX,
    StationType.WIDOX,
    StationType.DODO,
  ],
  plcStatus: "all",
  plbStatus: "all",
  status: "all",
  productCode: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
});
