import React, {
  useMemo,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import styles from "./account-details.module.scss";
import { useHistory } from "react-router-dom";
import { useForm, useApi, useModal, useMount } from "hooks";
import { initialState as formState } from "./account-details.state";
import {
  isEmailValid,
  formatDate,
  handleRequest,
  fleetAccountForm,
  getObjectValues,
  getObjectDifference,
  isTelephoneNumber,
  maxLength,
  capitalizeAll,
} from "utils";
import { ConfirmModal, SuccessModal } from "components/modals";
import { Loader } from "components/commons";
import {
  createFleetAccount,
  updateFleetAccount,
  getFleet,
  getPricingAccumulatedMovement,
} from "apis";
import { FormMode, Path, AccountStatus, DateTime } from "enums";
import locale from "localization";
import AccountDetailsFormModule from "./account-details-form.module";
import EditConfirm from "../account-edit/account-edit-confirm";
import { Provinces, Cities } from "assets";
import { SuccessFormSubmit, SuccessUpdate } from "images";
import classNames from "classnames";
import _ from "lodash";
import { getStationPricing } from "apis/station.api";
import Big from "big.js";

const AccountDetailsModule = ({ pageMode, fleetId, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [switchStatus, setSwitchStatus] = useState(false);
  const [provinceKeyFilter, setProvinceKeyFilter] = useState("");
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [editConfirmData, setEditConfirmData] = useState({});
  const [stationDepotName, setStationDepotName] = useState({});
  const [otherDetails, setOtherDetails] = useState({
    status: "",
    dateOnboard: "",
  });
  const [newEmailAdress, setNewEmailAddress] = useState({
    flag: false,
    emailAddresses: [],
    removedEmailAddresses: [],
  });
  const inputRef = useRef();

  const getPriceMovement = useApi({
    api: getPricingAccumulatedMovement,
  });

  const {
    request: getFleetRequest,
    loading: loadingFleet,
    result: getFleetResult,
  } = useApi({
    api: getFleet,
    modalError: true,
    params: {
      fleetId,
    },
  });

  const {
    request: getStationPricingRequest,
    loading: loadingStationPricing,
    result: getStationPricingResult,
  } = useApi({
    api: getStationPricing,
    modalError: true,
  });

  const computeMovement = useCallback((latestPumpPrice, basePrice) => {
    return Big(latestPumpPrice).minus(Big(basePrice)).toNumber();
  }, []);

  const computeContractPrice = useCallback((basePrice, movement, discount) => {
    return Big(basePrice || 0)
      .add(Big(movement || 0))
      .minus(Big(discount || 0))
      .toNumber();
  }, []);

  const form = useMemo(() => {
    let initialState = {};
    if (fleetId) {
      const data = getFleetResult;
      const pricingData = getStationPricingResult;

      if (data) {
        initialState = fleetAccountForm({
          data,
          viewMode,
          editMode,
          pricingData,
          computeMovement,
          computeContractPrice,
        });
        if (editMode) {
          setPreviousFormValues(_.cloneDeep(getObjectValues(initialState)));
          const checked = data.status === AccountStatus.Deactivated;
          setSwitchStatus(checked);
        }
        setOtherDetails({
          status: data.status,
          dateOnboard: formatDate(data.dateTimeOnboarded, DateTime.E),
          referenceStationType: data.referenceStation.stationType,
          depot: data.depot.name,
        });
      }
    }
    return formState(initialState);
  }, [
    editMode,
    fleetId,
    getFleetResult,
    viewMode,
    getStationPricingResult,
    computeMovement,
    computeContractPrice,
  ]);

  const confirmModal = useModal();
  const { close } = confirmModal;
  const history = useHistory();

  useMount(async () => {
    if (fleetId) {
      const res = await getFleetRequest();

      if (res.priceAssessmentDate && (viewMode || editMode)) {
        getStationPricingRequest({
          stationId: res.referenceStation.stationId,
          effectiveDate: res.priceAssessmentDate,
        });

        getPriceMovement?.request({
          effectiveAt: res.priceAssessmentDate,
        });
      }
    }
  });

  const {
    fields,
    modifyField,
    modifyForm,
    isFormSubmittable,
    submitForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
    validatedField,
  } = useForm({
    initialState: form,
  });

  const addRequest = useApi({
    api: createFleetAccount,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateFleetAccount,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      fleetId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;

    if (addMode) {
      delete params.status;

      params.baseDieselPrice = Big(params.baseDieselPrice)
        .add(Big(params.accuMovementDiesel || 0))
        .toNumber();
      params.baseGas91Price = Big(params.baseGas91Price)
        .add(Big(params.accuMovementGas91 || 0))
        .toNumber();
      params.baseGas95Price = Big(params.baseGas95Price)
        .add(Big(params.accuMovementGas95 || 0))
        .toNumber();
      params.baseGas97Price = Big(params.baseGas97Price)
        .add(Big(params.accuMovementGas97 || 0))
        .toNumber();
    }

    if (params.representativeEmail) {
      params.representativeEmail = params.representativeEmail.trim();
    }
    if (params.acquirerEmail) {
      params.acquirerEmail = params.acquirerEmail.trim();
    }

    if (params.fleetStations) {
      params.fleetStations = params.fleetStations.map(({ value }) => {
        return value;
      });
    }

    handleRequest(
      async () => {
        close();
        await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        let optionalObject = {};
        if (addMode) {
          optionalObject = {
            secondary: {
              text: locale.goToListOfFleet,
              onClick: () => {
                history.push(Path.FleetAccounts);
              },
            },
          };
        }
        props.show({
          title: locale.exclamatedSuccess,
          image: addMode ? SuccessFormSubmit : SuccessUpdate,
          content: addMode
            ? locale.accountActivationSuccessMessage
            : locale.detailHaveBeenSuccessfullyUpdated,
          primary: {
            text: addMode ? locale.addAnotherAccount : locale.gotIt,
            onClick: () => {
              props.close();
              if (addMode) {
                clearForm();
                history.push(Path.AddFleetAccount);
              } else {
                history.push(Path.ViewFleetAccountById(fleetId));
              }
            },
          },
          ...optionalObject,
        });
      },
      {
        F1006: () => {
          inputRef.current.focus();
          applyFieldErrors({
            shortName: locale.uniqueFuelCodePrefixAlreadyExist,
          });
        },
      }
    );
  };

  const getChangedValues = (compare) => {
    return getObjectDifference(previousFormValues, compare);
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();
    const updateParams = getChangedValues(currentFormValues);
    const updatingForm = Object.keys(updateParams).length > 0;
    const validUpdateForm = editMode && updatingForm;
    if (validUpdateForm) {
      setEditConfirmData({ updateParams, stationDepotName });
    }
    if (validUpdateForm || addMode) {
      confirmModal.show({
        title: addMode ? locale.saveDetailsAndSendEmail : locale.areYouSure,
        content: addMode
          ? locale.saveAndSendEmailContentMessage
          : newEmailAdress.flag
          ? locale.accountUpdateSaveWithEmail
          : locale.reviewChangesBelow,
        secondary: {
          text: locale.Cancel,
        },
        primary: {
          text: addMode
            ? locale.saveAndSendEmail
            : newEmailAdress.flag
            ? locale.saveAndSendEmail
            : locale.saveDetails,
          onClick: () => {
            const params = addMode ? currentFormValues : updateParams;
            submit(params);
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  const populateProvince = (name, { value }) => {
    setProvinceKey(value);
    modifyForm({
      [name]: { value },
      [fields.city.name]: {
        value: null,
      },
    });
  };

  const pronvinceData = useMemo(() => {
    if (Provinces) {
      const preparedData = Provinces.map((p) => {
        return {
          label: p.name,
          value: p.name,
        };
      });
      return preparedData;
    }
    return [];
  }, []);

  const setProvinceKey = (value = "") => {
    if (value) {
      const filteredProvince = Provinces.filter((data) => {
        return data.name === value;
      })[0];
      if (filteredProvince) {
        value = filteredProvince.key;
      }
    }
    setProvinceKeyFilter(value);
  };

  const clearProvince = (event, value, reason) => {
    if (reason === "clear") {
      setProvinceKey("");
    } else {
      setProvinceKey(value);
    }
  };

  const cities = useMemo(() => {
    const filteredCities = provinceKeyFilter
      ? Cities.filter((data) => {
          return data.province === provinceKeyFilter;
        })
      : Cities;
    const preparedData = filteredCities.map((p) => {
      return {
        label: p.name,
        value: p.name,
      };
    });
    return preparedData;
  }, [provinceKeyFilter]);

  const onChangeTypeOfBusinessCb = useCallback(
    (type) => {
      modifyField(fields.businessType.name, { value: type });
    },
    [fields.businessType.name, modifyField]
  );

  const computeAutoPopulatedData = useCallback(
    async (name, { value, label }) => {
      let result;
      if (name === "referenceStationId") {
        modifyForm({ [name]: { value, label } });

        if (!fields.priceAssessmentDate.value) {
          return;
        }
        result = await getStationPricingRequest({
          stationId: value,
          effectiveDate: formatDate(fields.priceAssessmentDate.value, DateTime.I),
        });
      }

      if (name === "priceAssessmentDate") {
        modifyForm({ [name]: { value } });
        if (!fields.referenceStationId.value) {
          return;
        }
        result = await getStationPricingRequest({
          stationId: fields.referenceStationId.value,
          effectiveDate: value,
        });
      }

      const baseDieselPrice = result[0].pumpPrice;
      const baseGas91Price = result[1].pumpPrice;
      const baseGas95Price = result[2].pumpPrice;
      const baseGas97Price = result[3].pumpPrice;

      const contractPriceDiesel = result[0].latestPumpPrice;
      const contractPriceGas91 = result[1].latestPumpPrice;
      const contractPriceGas95 = result[2].latestPumpPrice;
      const contractPriceGas97 = result[3].latestPumpPrice;

      const accuMovementDiesel = editMode
        ? result[0].accumulatedMovement
        : result[0].latestStationPricingTool?.pumpPrice - result[0].pumpPrice;
      const accuMovementGas91 = editMode
        ? result[1].accumulatedMovement
        : result[1].latestStationPricingTool?.pumpPrice - result[1].pumpPrice;
      const accuMovementGas95 = editMode
        ? result[2].accumulatedMovement
        : result[2].latestStationPricingTool?.pumpPrice - result[2].pumpPrice;
      const accuMovementGas97 = editMode
        ? result[3].accumulatedMovement
        : result[3].latestStationPricingTool?.pumpPrice - result[3].pumpPrice;

      modifyForm({
        [name]: name === "referenceStationId" ? { value, label } : { value },
        depotId: { value: result[0].depot.depotId },
        baseDieselPrice: { value: baseDieselPrice || 0 },
        baseGas91Price: { value: baseGas91Price || 0 },
        baseGas95Price: { value: baseGas95Price || 0 },
        baseGas97Price: { value: baseGas97Price || 0 },
        accuMovementDiesel: { value: accuMovementDiesel || 0 },
        accuMovementGas91: { value: accuMovementGas91 || 0 },
        accuMovementGas95: { value: accuMovementGas95 || 0 },
        accuMovementGas97: { value: accuMovementGas97 || 0 },
        discountDiesel: { value: "0" },
        discountGas91: { value: "0" },
        discountGas95: { value: "0" },
        discountGas97: { value: "0" },
        contractPriceDiesel: { value: contractPriceDiesel || 0 },
        contractPriceGas91: { value: contractPriceGas91 || 0 },
        contractPriceGas95: { value: contractPriceGas95 || 0 },
        contractPriceGas97: { value: contractPriceGas97 || 0 },
        sdumDiesel: { value: result[0].sdum || 0 },
        sdumGas91: { value: result[1].sdum || 0 },
        sdumGas95: { value: result[2].sdum || 0 },
        sdumGas97: { value: result[3].sdum || 0 },
        freightDiesel: { value: result[0].freight || 0 },
        freightGas91: { value: result[1].freight || 0 },
        freightGas95: { value: result[2].freight || 0 },
        freightGas97: { value: result[3].freight || 0 },
      });
    },
    [
      editMode,
      fields.priceAssessmentDate.value,
      fields.referenceStationId.value,
      getStationPricingRequest,
      modifyForm,
    ]
  );

  const selectRedemptionStationCb = useCallback(
    (name, { value, label, isAll }) => {
      modifyField(name, { value, label, isAll });
    },
    [modifyField]
  );

  const selectDepotCb = useCallback(
    (name, { value, label }) => {
      setStationDepotName({
        ...stationDepotName,
        depotId: label,
      });
      modifyField([name], { value: value, label: label });
    },
    [modifyField, stationDepotName]
  );

  const onChangeShortNameCb = useCallback(
    (name, { value }) => {
      if (maxLength({ value, limit: 5 }) || !value)
        modifyField(name, { value: capitalizeAll(value) });
    },
    [modifyField]
  );

  const onChangeTelephoneCb = useCallback(
    (name, { value }) => {
      if (isTelephoneNumber(value) || !value) modifyField(name, { value });
    },
    [modifyField]
  );

  const handleAddEmail = useCallback(
    (chipValue) => {
      const currentValue = [...fields?.emailAddresses?.value] || [];
      if (
        !_.find(currentValue, ["emailAddress", chipValue]) &&
        isEmailValid(chipValue) &&
        currentValue.length < 5
      ) {
        let emailInput = [];
        if (editMode) {
          const entryType = _.find(previousFormValues.emailAddresses, ["emailAddress", chipValue])
            ? { resend: true }
            : { new: true };

          emailInput = {
            emailAddress: chipValue,
            ...entryType,
          };
        } else {
          emailInput = chipValue;
        }

        currentValue.push(emailInput);
        modifyField(fields.emailAddresses.name, { value: currentValue });
      }
    },
    [
      editMode,
      fields.emailAddresses.name,
      fields.emailAddresses.value,
      modifyField,
      previousFormValues.emailAddresses,
    ]
  );

  const onDeleteEmailAdrresses = (chipValue, index) => {
    const newValue = [...fields?.emailAddresses?.value];
    if (newValue.includes(chipValue)) newValue.splice(index, 1);

    modifyField(fields.emailAddresses.name, { value: newValue });
  };

  const handleStatusChange = (event, deactivate) => {
    const status = deactivate ? AccountStatus.Deactivated : AccountStatus.Active;
    setSwitchStatus(deactivate);
    modifyField(fields.status.name, { value: status });
  };

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      const currentFormValues = getFormValues();
      const updateParams = getChangedValues(currentFormValues);
      const fieldsChanged = Object.keys(updateParams).length > 0;
      let newEntries,
        removedEntries = [];
      newEntries =
        currentFormValues.emailAddresses &&
        currentFormValues.emailAddresses.filter((item) => {
          return (
            !_.find(previousFormValues.emailAddresses, ["emailAddress", item.emailAddress]) ||
            item.resend
          );
        });
      removedEntries =
        previousFormValues.emailAddresses &&
        previousFormValues.emailAddresses.filter((item) => {
          return !_.find(currentFormValues.emailAddresses, ["emailAddress", item.emailAddress]);
        });
      setNewEmailAddress({
        ...newEmailAdress,
        flag: newEntries.length > 0,
        emailAddresses: newEntries,
        removedEmailAddresses: removedEntries,
      });
      setDisableUpdate(!isFormSubmittable || !fieldsChanged);
    }
    //eslint-disable-next-line
  }, [fields, loading]);

  console.log(fields);

  const stateForm = {
    switchStatus,
    viewMode,
    addMode,
    editMode,
    otherDetails,
    fields,
    isFormSubmittable,
    modifyField,
    modifyForm,
    loading,
    populateProvince,
    pronvinceData,
    clearProvince,
    cities,
    provinceKeyFilter,
    submitForm,
    handleSubmit,
    onChangeTypeOfBusinessCb,
    handleAddEmail,
    onDeleteEmailAdrresses,
    handleStatusChange,
    selectDepotCb,
    onChangeShortNameCb,
    onChangeTelephoneCb,
    selectRedemptionStationCb,
    inputRef,
    validatedField,
    computeAutoPopulatedData,
    loadingStationPricing,
    computeContractPrice,
    getPriceMovement,
  };

  return (
    <div>
      <Loader open={loadingFleet} />
      {!loadingFleet && (
        <div className={styles.container}>
          {!viewMode && (
            <>
              <ConfirmModal
                {...confirmModal}
                className={classNames({
                  [styles.editConfirmModal]: editMode,
                })}
              >
                {editMode && <EditConfirm {...editConfirmData} {...newEmailAdress} />}
              </ConfirmModal>
              <SuccessModal {...props} />
            </>
          )}
          <AccountDetailsFormModule {...stateForm} stationPricings={getStationPricingResult} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(AccountDetailsModule);
