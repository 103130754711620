import {
  Field,
  Filter,
  SearchField,
  Button,
  SelectProductCheckbox,
  Text,
} from "components/commons";
import locale from "localization";
import styles from "./station-filter.module.scss";
import SelectStationType from "components/field/dropdown/select-station-type";
import SelectPLCStatus from "components/field/dropdown/select-plc-status";
import SelectPLBStatus from "components/field/dropdown/select-plb-status";
import SelectStationStatus from "components/field/dropdown/select-station-status";
import { CircularProgress } from "@material-ui/core";
import { RefreshRounded } from "@material-ui/icons";

const StationFilter = ({
  filterState = {},
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  alertRequestCount,
}) => {
  const { stationType, productCode, plcStatus, plbStatus, status } = filterState || {};
  const { result: newUpdateCount = 0 } = alertRequestCount;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          clear={applyClearFilter}
          close={resetFilter}
        >
          <div
            style={{
              padding: "0 20px",
            }}
          >
            <SelectStationType
              labelPosition={"left"}
              className={styles.fieldSelect}
              fieldClassName={styles.fieldContent}
              labelClassName={styles.fieldLabel}
              value={stationType}
              name="stationType"
              onChange={modifyFilter}
            />
            <SelectPLCStatus
              labelPosition={"left"}
              className={styles.fieldSelect}
              fieldClassName={styles.fieldContent}
              labelClassName={styles.fieldLabel}
              value={plcStatus}
              name="plcStatus"
              onChange={modifyFilter}
              hasAll
            />
            <SelectPLBStatus
              labelPosition={"left"}
              className={styles.fieldSelect}
              fieldClassName={styles.fieldContent}
              labelClassName={styles.fieldLabel}
              value={plbStatus}
              name="plbStatus"
              onChange={modifyFilter}
              hasAll
            />
            <SelectStationStatus
              labelPosition={"left"}
              className={styles.fieldSelect}
              fieldClassName={styles.fieldContent}
              labelClassName={styles.fieldLabel}
              value={status}
              name="status"
              onChange={modifyFilter}
              hasAll
            />
            <SelectProductCheckbox
              name="productCode"
              value={productCode}
              onChange={(name, { value }) => {
                modifyFilter({
                  [name]: value,
                });
              }}
            />
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[
              locale.stationName,
              locale.businessName,
              locale.stationCode,
              `${locale.city}/${locale.province}`,
            ].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
            restrict={false}
          />
        </Field>
        <Field className={styles.downloadButton}>
          {alertRequestCount.loading ? (
            <CircularProgress
              style={{
                marginRight: "10px",
              }}
              size={"20px"}
            />
          ) : alertRequestCount.error ? (
            <Text
              style={{
                color: "#ee3b34",
                display: "flex",
                alignItems: "center",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                alertRequestCount.request();
              }}
            >
              <RefreshRounded
                style={{
                  marginRight: "5px",
                  color: "#ee3b34",
                }}
              />{" "}
              {locale.failedToLoad}
            </Text>
          ) : (
            Boolean(newUpdateCount) && (
              <Text
                style={{
                  marginRight: "10px",
                }}
              >
                {locale.populate(locale.youGotNew, [
                  newUpdateCount,
                  newUpdateCount === 1 ? locale.update.toString().toLowerCase() : locale.updates,
                ])}
              </Text>
            )
          )}
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default StationFilter;
