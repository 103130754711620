import React from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapDataToList, mapFilterToRequest } from "./dox-negotiated-discounts.mapper";
import { doxNegotiatedDiscountsFilterState } from "./dox-negotiated-discounts-filter.state";

import { doxNegotiatedDiscountsColumns } from "./dox-negotiated-discounts-columns";
import DataTableV2 from "components/commons/data-table/data-table-v2";

import { SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { generatePricingReportv2 } from "apis";

import DoxNegotiatedDiscountsFilter from "./dox-negotiated-discounts-filter";
import { getStationNegotiatedDiscount } from "apis/station.api";
import { useContext } from "react";
import { UserContext } from "contexts";

const DoxNegotiatedDiscountsModule = () => {
  const { sessionId: connectionId } = useContext(UserContext);
  const { filter, search, table } = useDataTable({
    api: { api: getStationNegotiatedDiscount },
    filter: { initialState: doxNegotiatedDiscountsFilterState(), mapper: mapFilterToRequest },
    table: { key: "stations", mapper: mapDataToList, columns: doxNegotiatedDiscountsColumns },
  });

  const exports = useExport({
    api: generatePricingReportv2,
    hasModal: true,
    reportType: "station-negotiated-discount",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      connectionId,
    },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          title={locale.doxNegotiatedDiscounts}
          subtitle={locale.viewAndMonitorNegotiatedDiscountsApprovedByDOXStationDealer}
        />
      </div>
      <DoxNegotiatedDiscountsFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default DoxNegotiatedDiscountsModule;
