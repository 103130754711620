import styles from "./station.module.scss";
import React from "react";
import classNames from "classnames";
import { formatDate, parseNumber } from "utils";
import { DateTime, Path, PLBStatus, PLCStatus, StationStatus } from "enums";
import moment from "moment";
import Products from "components/commons/product/products";
import { Button, PopOverMenu, Text } from "components/commons";
import StationStatusPill from "components/commons/status/station-status-pill";
import PLCStatusPill from "components/commons/status/plc-status-pill";
import PLBStatusPill from "components/commons/status/plb-status-pill";
import { MoreVertRounded } from "@material-ui/icons";
import locale from "localization";
import AlertIconTooltip from "components/commons/alert/alert-icon-tooltip";

export const mapDataToList = ({
  data,
  history,
  onboardInPLB,
  onboardInPLC,
  deactivatePLC,
  activatePLC,
  deactivatePLB,
  hideStationPLC,
  showStationPLC,
  activatePLB,
  clearStationAlert,
  sendActivationEmail,
  sendWelcomeEmail,
}) => {
  const {
    stationId,
    name,
    stationCode,
    businessName,
    stationType,
    statusUpdatedAt,
    city,
    province,
    opensAt,
    closesAt,
    stationProduct = {},
    alertType = "",
    status,
    plbStatus: plbS = PLBStatus.NotOnboarded,
    plcStatus: plcS = PLCStatus.NotOnboarded,
    plbStatusUpdatedAt,
    plcStatusUpdatedAt,
    updatedAt,
    priceToolDeactivated,
    bankAccountName,
  } = data;
  const plbStatus = plbS || PLBStatus.NotOnboarded;
  const plcStatus = plcS || PLCStatus.NotOnboarded;
  const { diesel, gas91, gas95, gas97 } = stationProduct || {};
  const highlight = Boolean(alertType);

  const options = [
    {
      content: locale.viewStationDetails,
      onClick: () => {
        history.push(Path.ViewStationId(stationId));
      },
    },
  ];

  if (plcStatus === PLCStatus.NotOnboarded) {
    options.push({
      content: locale.onboardInPLC,
      onClick: () => {
        onboardInPLC({ stationId, stationType, hasSettlementInfo: Boolean(bankAccountName) });
        // history.push(Path.ViewStationId(stationId));
      },
    });
  }

  if (
    [PLCStatus.Active, PLCStatus.Deactivated, PLCStatus.Hidden].includes(plcStatus) &&
    !priceToolDeactivated
  ) {
    options.push({
      content: [PLCStatus.Active, PLCStatus.Hidden].includes(plcStatus)
        ? locale.deactivateInPLC
        : locale.reactivateInPLC,
      onClick: () => {
        if ([PLCStatus.Active, PLCStatus.Hidden].includes(plcStatus)) {
          deactivatePLC(stationId);
        } else {
          activatePLC(stationId);
        }
        // history.push(Path.ViewStationId(stationId));
      },
    });
  }

  if (plcStatus === PLCStatus.Active || plcStatus === PLCStatus.Hidden) {
    options.push({
      content: plcStatus === PLCStatus.Active ? locale.hideInPLC : locale.unhideInPLC,
      onClick: () => {
        if (PLCStatus.Active === plcStatus) {
          hideStationPLC(stationId);
        } else {
          showStationPLC(stationId);
        }
        // history.push(Path.ViewStationId(stationId));
      },
    });
  }

  if (plbStatus === PLBStatus.NotOnboarded) {
    options.push({
      content: locale.onboardInPLB,
      onClick: () => {
        onboardInPLB({ stationId, stationType, hasSettlementInfo: Boolean(bankAccountName) });
        // history.push(Path.ViewStationId(stationId));
      },
    });
  }

  if ([PLBStatus.Active, PLBStatus.Deactivated].includes(plbStatus) && !priceToolDeactivated) {
    options.push({
      content: [PLBStatus.Active].includes(plbStatus)
        ? locale.deactivateInPLB
        : locale.reactivateInPLB,
      onClick: () => {
        if ([PLBStatus.Active].includes(plbStatus)) {
          deactivatePLB(stationId);
        } else {
          activatePLB(stationId);
        }
        // history.push(Path.ViewStationId(stationId));
      },
    });
  }

  if (
    [StationStatus.ForActivation, StationStatus.Pending, StationStatus.Expired].includes(status)
  ) {
    options.push({
      content: locale.sendActivationEmail,
      onClick: () => {
        sendActivationEmail({
          stationId,
          stationName: name,
        });
      },
    });
  }

  if (status === StationStatus.Active) {
    options.push({
      content: locale.sendResendWelcomeEmail,
      onClick: () => {
        sendWelcomeEmail({
          stationId,
          stationName: name,
        });
      },
    });
  }

  return {
    className: highlight ? styles.highlightRow : styles.row,
    id: <LabelAndValue highlight={highlight} label={name} value={stationCode} />,
    name: <LabelAndValue highlight={highlight} label={businessName} value={stationType} />,
    dateModified: updatedAt ? (
      <div className="min-80">
        <Highlight highlight={highlight}>{formatDate(updatedAt, DateTime.K)}</Highlight>
      </div>
    ) : (
      "-"
    ),
    cityProvince: <LabelAndValue highlight={highlight} label={city} value={province} />,
    operatingHours: (
      <div className="min-70">
        <Highlight highlight={highlight}>{`${moment(opensAt, "HH:mm:ss").format(
          DateTime.L
        )} - ${moment(closesAt, "HH:mm:ss").format(DateTime.L)}`}</Highlight>
      </div>
    ),
    products: (
      <div
        style={{
          minWidth: "65px!important",
        }}
      >
        <Products
          textClassName={classNames({
            [`${styles.highlight} ${styles.blue}`]: highlight,
          })}
          diesel={diesel}
          gas91={gas91}
          gas95={gas95}
          gas97={gas97}
        />
      </div>
    ),
    status: (
      <div>
        <div className="min-120">
          <StationStatusPill value={status} />
        </div>
        <div className="min-70 max-70">
          <Subtitle highlight={highlight} value={formatDate(statusUpdatedAt, DateTime.K)} />
        </div>
      </div>
    ),
    plcStatus: (
      <div>
        <div className="min-120">
          <PLCStatusPill value={plcStatus} />
        </div>
        <div className="min-70 max-70">
          <Subtitle highlight={highlight} value={formatDate(plcStatusUpdatedAt, DateTime.K)} />
        </div>
      </div>
    ),
    plbStatus: (
      <div>
        <div className="min-120">
          <PLBStatusPill value={plbStatus} />
        </div>
        <div className="min-70 max-70">
          <Subtitle highlight={highlight} value={formatDate(plbStatusUpdatedAt, DateTime.K)} />
        </div>
      </div>
    ),
    actions: (
      <div
        className="flex"
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <PopOverMenu
          options={options}
          style={{
            margin: "auto",
          }}
        >
          <MoreVertRounded className={styles.icon} />
        </PopOverMenu>
      </div>
    ),
    alert: highlight ? (
      <AlertIconTooltip alertType={alertType}>
        <div>
          {alertType === "yellow"
            ? locale.stationTypeChangedFromPriceTool
            : locale.stationDisappeared}
          <Button
            textButton
            loading={clearStationAlert.loading}
            onClick={() => {
              clearStationAlert.request({
                stationId,
              });
              if (alertType === "yellow") {
                history.push(Path.ViewStationId(stationId));
              }
            }}
          >
            {alertType === "yellow" ? locale.viewStationDetails : locale.okGotIt}
          </Button>
        </div>
      </AlertIconTooltip>
    ) : null,
  };
};

const Highlight = ({ children, highlight }) => {
  return (
    <div
      className={classNames(styles.blue, {
        [`${styles.highlight}`]: highlight,
      })}
    >
      <Text>{children}</Text>
    </div>
  );
};

const Subtitle = ({ value, highlight }) => {
  return (
    <div
      style={{
        marginTop: "5px",
      }}
      className={classNames(styles.gray, styles.subtitle, {
        bold: highlight,
      })}
    >
      {value}
    </div>
  );
};

const LabelAndValue = ({ label, value, highlight }) => {
  return (
    <div className="max-65">
      <Highlight highlight={highlight}>{label}</Highlight>
      <Subtitle value={value} highlight={highlight} />
    </div>
  );
};

export const mapFilterToRequest = ({
  plcStatus,
  plbStatus,
  status,
  stationType,
  page,
  perPage,
  order,
  productCode,
  ...filterState
}) => {
  return {
    ...filterState,
    stationType: stationType.join(","),
    plcStatus: plcStatus === "all" ? null : plcStatus,
    plbStatus: plbStatus === "all" ? null : plbStatus,
    status: status === "all" ? null : status,
    page,
    perPage,
    order,
    productCode: productCode.length === 4 ? null : productCode.sort().join(","),
  };
};

export const mapFormToRequest = (form) => {
  console.log(form);
  const {
    stationCode,
    stationName,
    fullAddress,
    province,
    city,
    openingTime,
    closingTime,
    latitude,
    longitude,
    emailAddress,
    rtmName,
    rtmEmailAddress,
    mobileNumberReceiving,
    bankAccountName,
    bankAccountNumber,
    nameOfBank,
    settlementEmail,
    diesel,
    gas91,
    gas95,
    gas97,
    platformType,
  } = form;

  return {
    code: Number(stationCode),
    stationName,
    address: fullAddress,
    province,
    city,
    opensAt: moment(openingTime).format(DateTime.M),
    closesAt: moment(closingTime).format(DateTime.M),
    latitude: Number(latitude),
    longitude: Number(longitude),
    email: emailAddress,
    rtmName,
    rtmEmail: rtmEmailAddress,
    platformType,
    mobileNumber: mobileNumberReceiving ? `0${mobileNumberReceiving}` : "",
    maxDiscountDiesel: diesel ? parseNumber(diesel) : null,
    maxDiscountGas91: gas91 ? parseNumber(gas91) : null,
    maxDiscountGas95: gas95 ? parseNumber(gas95) : null,
    maxDiscountGas97: gas97 ? parseNumber(gas97) : null,
    bankAccountName: bankAccountName || "",
    bankAccountNumber: bankAccountNumber || "",
    bankName: nameOfBank || "",
    settlementEmail: settlementEmail || "",
  };
};
