import { DataTable, Intro } from "components/commons";
import { useFilter, useMount, useModal } from "hooks";
import React from "react";
import { useCallback } from "react";
import VoucherFilter from "./voucher-filter";
import { voucherFilterState } from "./voucher-filter.state";
import { SuccessModal } from "components/modals"
import locale from "localization"

const VoucherList = ({ title, filters, api, columns = [], mapResult, description, exportApi }) => {
  const { loading, result = {}, request } = api;

  const { data, count } = mapResult(result);
  
  const successModal = useModal();

  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    resetFilter,
    clearFilter,
  } = useFilter(voucherFilterState(filters));

  const fetchData = useCallback(
    async (params = requestState) => {
      const requestParams = { ...submitFilter({ ...params }) };
      requestParams.statuses = requestParams.statuses.join(",");
      await request(requestParams, fetchData);
    },
    [request, requestState, submitFilter]
  );

  useMount(() => {
    fetchData({ ...requestState });
  });
  return (
    <div>
      <SuccessModal {...successModal}/>
      <div style={{ marginBottom: "30px" }}>
        <Intro title={title} subtitle={description} />
      </div>
      <VoucherFilter
        filterState={filterState}
        submitFilter={(obj = {}) => {
          const { requestState } = modifyFilters({ page: 1, ...obj });
          fetchData(requestState);
        }}
        resetFilter={resetFilter}
        filterCount={filterCount}
        filters={filters}
        modifyFilters={modifyFilters}
        clearFilter={() => {
          const { requestState } = clearFilter();
          fetchData(requestState);
        }}
        exporting={exportApi.loading}
        isExportDisbaled={count === 0}
        onExport={async () => {
          const { statuses, searchKey, startDate, endDate } = requestState || {};
          const res = await exportApi.request({
            statuses: statuses.join(","),
            searchKey: searchKey || "",
            startDate,
            endDate,
            connectionId: localStorage.getItem("connectionId")
          });
          if (res?.downloadUrl) {
            window.open(res?.downloadUrl, "_blank");
          } else {
            successModal.show({
              title: locale.exclamatedSuccess,
              content: locale.reportGenerated,
            });
          }
        }}
      />
      <DataTable
        dataCount={count}
        loading={loading}
        columns={columns}
        data={data}
        page={filterState.page}
        pageSize={filterState.perPage}
        onChangePage={(page) => {
          const { requestState } = modifyFilters({
            page,
          });
          fetchData(requestState);
        }}
        onChangePageSize={(perPage) => {
          const { requestState } = modifyFilters({
            perPage,
          });
          fetchData(requestState);
        }}
      />
    </div>
  );
};

export default VoucherList;
