import moment from "moment";
import { Autocomplete, Field, Filter, SearchField, Button } from "components/commons";
import locale from "localization";
import styles from "./settlement-history-filter.module.scss";

const months = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

const years = () => {
  const yeardiff = moment().year() - 2017;
  const yearOutput = [];
  for (let i = yeardiff; i >= 0; i--) {
    yearOutput.push({
      label: `${2017 + i}`,
      value: `${2017 + i}`,
    });
  }

  return yearOutput;
};

const SettlementHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          clear={applyClearFilter}
          close={resetFilter}
        >
          <div
            style={{
              margin: "0px 20px",
            }}
          >
            <Field
              className={styles.filterInput}
              labelPosition={"left"}
              label={locale.month}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                options={months}
                value={filterState?.month}
                onChange={(_, { value }) => {
                  modifyFilter({ month: value });
                }}
              />
            </Field>
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.year}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                options={years()}
                value={filterState?.year}
                onChange={(_, { value }) => {
                  modifyFilter({ year: value });
                }}
              />
            </Field>
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.oilcoSettlementId}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
            restrict={false}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default SettlementHistoryFilter;
