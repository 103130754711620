import React from "react";
import { Autocomplete, DateRange, Field, SearchField, Filter, Button } from "components/commons";

import { AccountStatus, FleetAccountType } from "enums";
import locale from "localization";
import { prettifyAccountStatus, prettifyFleetAccountType } from "utils";

import styles from "./account-list-filter.module.scss";

const AccountListFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filerCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter submit={applyFilter} close={resetFilter} clear={applyClearFilter}>
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Field
              className={styles.date}
              label={locale.dateOnboarded}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            <Field
              className={styles.filterInput}
              label={locale.status}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="status"
                value={filterState.status}
                options={[
                  { label: locale.all, value: "all" },
                  {
                    label: prettifyAccountStatus(AccountStatus.Active),
                    value: AccountStatus.Active,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Deactivated),
                    value: AccountStatus.Deactivated,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Pending),
                    value: AccountStatus.Pending,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Expired),
                    value: AccountStatus.Expired,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Inactive),
                    value: AccountStatus.Inactive,
                  },
                ]}
                onChange={(name, { value }) => modifyFilter({ [name]: value })}
              />
            </Field>
            <Field
              className={styles.date}
              label={locale.accountType}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="accountType"
                value={filterState.accountType}
                options={[
                  { label: locale.all, value: "all" },
                  {
                    label: prettifyFleetAccountType(FleetAccountType.FleetAccount),
                    value: FleetAccountType.FleetAccount,
                  },
                  {
                    label: prettifyFleetAccountType(FleetAccountType.KeyAccount),
                    value: FleetAccountType.KeyAccount,
                  },
                ]}
                onChange={(name, { value }) => modifyFilter({ [name]: value })}
              />
            </Field>
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={locale.businessNameNatureOfBusiness}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default AccountListFilter;
