import React, { useCallback, useMemo } from "react";
import VoucherForm from "./voucher-form";
import useForm from "hooks/useForm.v2";
import { voucherFormInitialState } from "./voucher-campaign.state";
import { useApi, useModal, useMount } from "hooks";
import { Path } from "enums";
import { mapFormToRequest } from "./voucher.mapper";
import { Button } from "components/commons";
import locale from "localization";
import { ConfirmModal, SuccessModal } from "components/modals";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SuccessFormSubmit } from "images";
import { createVoucherCampaignV2 } from "apis/campaign-program.api";
import { uploadFile } from "apis/storage.api";

const AddVoucher = () => {
  const history = useHistory();

  const form = useForm(voucherFormInitialState);
  const { fields = {} } = form;
  const { isFormSubmittable } = form;
  const successModal = useModal();
  const confirmModal = useModal();

  const createRequest = useApi({
    api: createVoucherCampaignV2,
    handleOwnError: {
      badrequest: true,
    },
  });

  const uploadToStorage = useApi({
    api: uploadFile,
  });

  useMount(() => {
    form.initializeForm({}, true);
  });

  const formFields = useMemo(() => {
    const { fields, ...value } = form;
    const obj = {};
    Object.keys(fields).forEach((key) => {
      const fieldKeys = Object.keys(fields[key]);
      obj[key] = {
        ...fields[key],
        visible: fieldKeys.includes("visible") ? fields[key].visible : true,
      };
    });
    const { ...fieldObj } = obj;
    return {
      ...value,
      fields: {
        ...fieldObj,
      },
    };
  }, [form]);

  const { voucherPrefix = {}, campaignName = {} } = fields;

  const onSubmit = useCallback(async () => {
    form.onSubmit((param) => {
      confirmModal.show({
        title: locale.createCampaign,
        content: locale.areYouSureProceedCreatingCampaign,
        primary: {
          text: locale.yesCreateCampaign,
          onClick: async () => {
            let campaignImageUrl = "";
            if (param.campaignImage?.length) {
              const res = await uploadToStorage.request({
                file: param.campaignImage[0],
                fileName: param.campaignImage[0].name,
              });
              campaignImageUrl = res.data.fileUrl;
            }
            try {
              await createRequest.request(
                mapFormToRequest({
                  ...param,
                  campaignImage: campaignImageUrl,
                })
              );
              confirmModal.close();
              successModal.show({
                content: locale.voucherCampaignHasSuccessfullyCreated,
              });
            } catch (e) {
              const { errorCode } = e?.data || {};
              const errors = {
                VC1000: () => {
                  voucherPrefix.onChange(voucherPrefix.name, {
                    value: voucherPrefix.value,
                    errorType: "VC1000",
                  });
                },
                VC1008: () => {
                  campaignName.onChange(campaignName.name, {
                    value: campaignName.value,
                    errorType: "VC1008",
                  });
                },
              };
              if (errors[errorCode]) {
                confirmModal.close();
                errors[errorCode]();
              } else {
                e.showError();
              }
            }
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            confirmModal.close();
          },
        },
      });
    });
  }, [
    confirmModal,
    createRequest,
    successModal,
    form,
    uploadToStorage,
    voucherPrefix,
    campaignName,
  ]);
  console.log(voucherPrefix);
  return (
    <div>
      <VoucherForm isFormSubmittable={isFormSubmittable} form={formFields} onSubmit={onSubmit} />
      <div
        style={{
          marginTop: "50px",
        }}
      >
        <Button
          loading={createRequest.loading || uploadToStorage.loading}
          onClick={onSubmit}
          primary
          disabled={!isFormSubmittable}
        >
          {locale.create}
        </Button>
      </div>
      <ConfirmModal {...confirmModal} loading={createRequest.loading || uploadToStorage.loading} />
      <SuccessModal
        image={SuccessFormSubmit}
        title={locale.exclamatedSuccess}
        content={locale.voucherCampaignHasSuccessfullyCreated}
        {...successModal}
        close={() => {
          history.push(Path.VoucherCampaign);
          successModal.close();
        }}
        secondary={{
          text: locale.goToVoucherList,
          onClick: () => {
            history.push(Path.VoucherCampaign);
            successModal.close();
          },
        }}
        primary={{
          text: locale.add,
          onClick: () => {
            form.initializeForm({}, true);
            successModal.close();
          },
        }}
      />
    </div>
  );
};

export default AddVoucher;
