import { LubeServSite, LubeServPreferredTime } from "enums";
import OilChangeType from "enums/oil-change-type";

export const lubeServOnSiteBookingsFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  type: [
    OilChangeType.dieselMonoGrade,
    OilChangeType.dieselMultiGrade,
    OilChangeType.dieselFullySynthetic,
    OilChangeType.gasolineMonoGrade,
    OilChangeType.gasolineMultiGrade,
    OilChangeType.gasolineFullySynthetic,
  ],
  lubeServSite: [LubeServSite.LubeServAnabuImusCavite, LubeServSite.LubeServTelabastaganPampanga],
  selectedPreferredDate: null,
  selectedPreferredTime: [
    LubeServPreferredTime.eightAM,
    LubeServPreferredTime.nineAM,
    LubeServPreferredTime.tenAM,
    LubeServPreferredTime.elevenAM, 
    LubeServPreferredTime.onePM,
    LubeServPreferredTime.twoPM,
    LubeServPreferredTime.threePM,
    LubeServPreferredTime.fourPM
  ],
  page: 1,
  perPage: 10,
});
