import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import WholesalePriceModule from "../modules/pricing/wholesale-price/wholesale-price.module";
import EditWholesalePriceModule from "../modules/pricing/edit-wholesale-price/edit-wholesale-price.module";
// import DownloadList from "modules/download/download-list/download-list.module";
import PricingChangesModule from "modules/pricing/pricing-changes/pricing-changes.module";
import FleetPriceModule from "modules/pricing/fleet/fleet-pricing.module";
import BatchPricingChangesModule from "modules/pricing/pricing-changes/batch-pricing-changes/batch-pricing-changes.module";
import DoxNegotiatedDiscountsModule from "modules/pricing/dox-negotiated-discounts/dox-negotiated-discounts.module";

const PricingPage = () => {
  return (
    <Switch>
      <Route exact path={Path.WholesalePrice} component={WholesalePriceModule} />
      <Route exact path={Path.EditWholesalePrice} component={EditWholesalePriceModule} />
      {/* <Route exact path={Path.Downloads} component={DownloadList} /> */}
      <Route exact path={Path.PricingChanges} component={PricingChangesModule} />
      <Route exact path={Path.FleetPrices} component={FleetPriceModule} />
      <Route exact path={Path.BatchPricingChanges} component={BatchPricingChangesModule} />
      <Route exact path={Path.DoxNegotiatedDiscount} component={DoxNegotiatedDiscountsModule} />
      <Redirect to={Path.Pricing} />
    </Switch>
  );
};

export default PricingPage;
