import { Post } from "utils";

export const uploadFile = async ({ file, fileName }) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", fileName);
  const res = await Post(`ms-storage/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log(res);
  return res;
};
