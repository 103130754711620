import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path, UserGroup } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import locale from "localization";
import { useContext } from "react";
import { FeatureFlagContext, UserContext } from "contexts";
import { GearIcon } from "images";
import { Role, Portal } from "enums";

const Navigation = [
  {
    label: locale.dashboard,
    link: Path.Slash,
    icon: "icon-dashboard",
    key: "DASHBOARD",
    permission: [Role.PortalAdmin],
  },
  // {
  //   label: locale.myApproval,
  //   icon: "icon-approval",
  //   link: Path.MyApproval,
  //   exact: true,
  // },
  {
    label: locale.pricing,
    icon: "icon-pricing",
    link: Path.Pricing,
    key: "PRICING",
    items: [
      // {
      //   label: locale.wholesalePrice,
      //   child: true,
      //   link: Path.WholesalePrice,
      //   key: "WHOLE_SALE_PRICE",
      //   isSeaoilRetail: true,
      // },
      {
        label: locale.fleetPrices,
        child: true,
        link: Path.FleetPrices,
        permission: [Role.PortalAdmin, Role.RetailPricing, Role.Audit],
      },
      {
        label: locale.pricingChanges,
        child: true,
        link: Path.PricingChanges,
        permission: [Role.PortalAdmin, Role.RetailPricing, Role.Audit],
      },
      {
        label: locale.doxNegotiatedDiscounts,
        child: true,
        link: Path.DoxNegotiatedDiscounts,
        key: "DOX_NEGOTIATED_DISCOUNT",
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin, Role.RetailPricing, Role.Audit],
  },
  // {
  //   label: locale.inventory,
  //   icon: "icon-inventory",
  //   link: Path.Inventory,
  //   exact: true,
  // },
  // {
  //   label: locale.admin,
  //   icon: "icon-admin",
  //   link: Path.Admin,
  //   exact: true,
  // },
  {
    label: locale.payments,
    icon: "icon-payment",
    link: Path.Payments,
    key: "PAYMENTS",
    isSeaoilRetail: true,
    items: [
      {
        label: locale.oilcoSettlementHistory,
        child: true,
        link: Path.SettlementHistory,
        key: "SETTLEMENT_HISTORY",
        permission: [Role.PortalAdmin, Role.Accounting, Role.Audit],
      },
      {
        label: locale.doxPremiumPayment,
        child: true,
        link: Path.PremiumPayout,
        key: "PREMIUM_PAYMENT",
        permission: [Role.PortalAdmin, Role.Accounting, Role.Audit],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.Audit],
  },
  {
    label: locale.accounts,
    icon: "icon-fleet-activity",
    key: "ACCOUNTS",
    link: Path.Accounts,
    items: [
      {
        label: locale.fleetAccountList,
        child: true,
        link: Path.FleetAccounts,
        permission: [Role.PortalAdmin, Role.Operations, Role.RetailPricing],
      },
      {
        label: locale.stationAccountList,
        child: true,
        link: Path.Station,
        isSeaoilRetail: true,
        key: "STATION_ACCOUNT",
        permission: [Role.PortalAdmin, Role.Marketing, Role.Operations, Role.Audit],
      },
    ],
    permission: [Role.PortalAdmin, Role.Marketing, Role.Operations, Role.Audit, Role.RetailPricing],
  },
  {
    label: locale.campaignsAndPrograms,
    icon: "icon-campaigns",
    link: Path.Campaigns,
    key: "CAMPAIGNS_PROGRAMS",
    isSeaoilRetail: true,
    items: [
      {
        label: locale.campaigns,
        child: true,
        link: Path.VoucherCampaign,
        key: "CAMPAIGNS",
        permission: [Role.PortalAdmin, Role.Finance, Role.Audit],
      },
      {
        label: locale.vouchers,
        child: true,
        link: Path.Vouchers,
        key: "VOUCHERS",
        permission: [Role.PortalAdmin, Role.Finance, Role.Audit],
      },
    ],
    permission: [Role.PortalAdmin, Role.Finance, Role.Audit],
  },
  {
    label: locale.otherServices,
    customIcon: <img src={GearIcon} alt="gear" className={styles.customIcon} />,
    link: Path.ConsumerOtherServices,
    key: "CONSUMER_OTHER_SERVICES",
    items: [
      {
        label: locale.lubeServOnWheelsBookings,
        link: Path.LubeServOnWheelsBookings,
        key: "LUBESERV_ON_WHEELS_BOOKINGS",
        child: true,
        permission: [Role.PortalAdmin, Role.LubeServ],
      },
      {
        label: locale.lubeServOnSite,
        link: Path.LubeServOnSiteBookings,
        key: "LUBESERV_ON_SITE_BOOKINGS",
        child: true,
        permission: [Role.PortalAdmin, Role.LubeServ],
      },
    ],
    permission: [Role.PortalAdmin, Role.LubeServ, Role.Operations, Role.Audit],
  },
  {
    label: locale.accessAuthorization,
    link: Path.AccessAuthorization,
    key: "ACCESS_AUTHORIZATION",
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.UserAccess,
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin],
  },
];

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  const { role } =
    user?.userAccesses?.find((element) => {
      return element.portal === Portal.OILCO;
    }) || {};
  console.log(role);
  return (
    <div className={styles.sidebarContainer}>
      <List className={styles.sidebarList}>
        {Navigation?.filter((child) => {
          return child.key ? hasFeature(`PAGE_${child.key}`) : true;
        })
          .filter((child) => {
            return child.isSeaoilRetail
              ? !(user.groups.indexOf(UserGroup.SeaoilRetailPricing) > -1)
              : true;
          })
          .map((item, index) => {
            if (!item) return <></>;

            if (!user.userAccesses)
              return (
                <span key={index}>
                  {item.items?.length > 0 ? (
                    <ChildItem item={item} />
                  ) : (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  )}
                </span>
              );

            return item.permission?.map((permission) => {
              return permission === role ? (
                <span key={index}>
                  {item.items?.length > 0 ? (
                    <ChildItem item={item} role={role} />
                  ) : (
                    <NavLink
                      to={item.link}
                      exact={true}
                      className={classNames(styles.listItem)}
                      activeClassName={styles.active}
                    >
                      <span className={styles.item}>
                        <span className={styles.listIconRoot}>
                          {item.icon && <span className={`icon ${item.icon}`} />}
                        </span>
                        <span className={styles.listText}>{item.label}</span>
                      </span>
                    </NavLink>
                  )}
                </span>
              ) : (
                <></>
              );
            });
          })}
      </List>
    </div>
  );
};

const ChildItem = ({ item, role }) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.customIcon}
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .filter((child) => {
              return child.isSeaoilRetail
                ? !(user.groups.indexOf(UserGroup.SeaoilRetailPricing) > -1)
                : true;
            })
            .map((child, childKey) => {
              return child.permission?.map((permission) => {
                return permission === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Sidebar;
