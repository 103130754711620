import { Field, Modal, TextField, Text, ActionButton, Title } from "components/commons";
import React from "react";
import locale from "localization";
import useForm from "hooks/useForm.v2";
import { updateStationStatus } from "apis/account-stations";
import { useApi } from "hooks";
import { InputAmount } from "components/field";
import { onboardPLBState } from "./onboard-plb-form.state";
import { PLBStatus } from "enums";
import { useEffect } from "react";

const OnboardPLBModal = ({
  refresh,
  stationId,
  stationType,
  hasSettlementInfo = false,
  ...modal
}) => {
  const form = useForm(onboardPLBState);
  const { fields, initializeForm } = form;
  const {
    plbMaxDiscount = {},
    diesel = {},
    gas91 = {},
    gas95 = {},
    gas97 = {},
    bankAccountName = {},
    bankAccountNumber = {},
    nameOfBank = {},
    settlementEmail = {},
  } = fields;

  const onboardRequest = useApi({
    api: updateStationStatus,
  });

  const { loading } = onboardRequest;
  useEffect(() => {
    if (stationType) {
      const showSettlementInfo = !hasSettlementInfo && ["CODO", "DODO"].includes(stationType);
      const showPricing = ["WIDOX", "DODOX"].includes(stationType);
      initializeForm(
        {
          bankAccountName: {
            value: showSettlementInfo ? bankAccountName.value : "",
            validations: {
              ...bankAccountName.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          nameOfBank: {
            value: showSettlementInfo ? nameOfBank.value : "",
            validations: {
              ...nameOfBank.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          bankAccountNumber: {
            value: showSettlementInfo ? bankAccountNumber.value : "",
            validations: {
              ...bankAccountNumber.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          settlementEmail: {
            value: showSettlementInfo ? settlementEmail.value : "",
            validations: {
              ...settlementEmail.validations,
              isRequired: showSettlementInfo,
            },
            visible: showSettlementInfo,
          },
          plbMaxDiscount: {
            visible: showPricing,
          },
          diesel: {
            label: "Diesel",
            validations: {
              isNumber: true,
              isRequired: showPricing,
            },
          },
          gas91: {
            label: "Gas 91",
            validations: {
              isNumber: true,
              isRequired: showPricing,
            },
          },
          gas95: {
            label: "Gas 95",
            validations: {
              isNumber: true,
              isRequired: showPricing,
            },
          },
          gas97: {
            label: "Gas 97",
            validations: {
              isNumber: true,
              isRequired: showPricing,
            },
          },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.active]);

  return (
    <Modal
      {...modal}
      title={locale.onBoardNowinPLB}
      style={{
        width: "600px",
      }}
    >
      <Text className="text-center">{locale.plbOnboardText}</Text>

      {[
        {
          title: locale.plbPricingInformation,
          fields: [
            {
              ...plbMaxDiscount,
              content: plbMaxDiscount.content || (
                <div className="flex items-center">
                  {[diesel, gas91, gas95, gas97].map((productField, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <Field {...productField}>
                          <div
                            style={{
                              width: "95px",
                            }}
                          >
                            <InputAmount innerLabel {...productField} />
                          </div>
                        </Field>
                      </div>
                    );
                  })}
                </div>
              ),
            },
          ],
        },
        {
          title: locale.settlementInformation,
          fields: [
            {
              ...bankAccountName,
              content: bankAccountName.content || (
                <TextField {...bankAccountName} disabled={loading} />
              ),
            },
            {
              ...bankAccountNumber,
              content: bankAccountNumber.content || (
                <TextField {...bankAccountNumber} disabled={loading} />
              ),
            },
            {
              ...nameOfBank,
              content: nameOfBank.content || <TextField {...nameOfBank} disabled={loading} />,
            },
            {
              ...settlementEmail,
              content: settlementEmail.content || (
                <TextField {...settlementEmail} disabled={loading} />
              ),
            },
          ],
        },
      ]
        .filter((group) => {
          const hasVisible = group.fields.find((item) => item.visible);
          return hasVisible;
        })
        .map((group, groupIndex) => {
          return (
            <div
              key={groupIndex}
              style={{
                maxWidth: "600px",
              }}
            >
              <Title xsmall>{group.title}</Title>
              <div>
                {group.fields.map((field, fieldIndex) => {
                  return (
                    <Field
                      childrenStyle={{
                        minWidth: "400px",
                        maxWidth: "400px",
                      }}
                      {...field}
                      key={fieldIndex}
                      labelPosition="left"
                      label={field.label}
                      error={field.error}
                      messages={field.messages}
                    >
                      {field.content || (
                        <Text strong>{typeof field.value === "string" ? field.value : "-"}</Text>
                      )}
                    </Field>
                  );
                })}
              </div>
            </div>
          );
        })}

      <ActionButton
        right
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              modal.close();
              initializeForm({}, true);
            },
          },
          {
            loading: onboardRequest.loading,
            text: locale.yesOnboardNow,
            onClick: () => {
              form.onSubmit(
                async ({
                  bankAccountName,
                  bankAccountNumber,
                  nameOfBank: bankName,
                  settlementEmail,
                  diesel,
                  gas91,
                  gas95,
                  gas97,
                }) => {
                  await onboardRequest.request({
                    stationId,
                    bankAccountName,
                    bankAccountNumber,
                    bankName,
                    settlementEmail,
                    maxDiscountDiesel: diesel ? Number(diesel) : null,
                    maxDiscountGas91: gas91 ? Number(gas91) : null,
                    maxDiscountGas95: gas95 ? Number(gas95) : null,
                    maxDiscountGas97: gas97 ? Number(gas97) : null,
                    plbStatus: PLBStatus.Active,
                  });
                  modal.close();
                  initializeForm({}, true);
                  refresh();
                }
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

export default OnboardPLBModal;
