const OilChangeType = {
    dieselMonoGrade: 'diesel-monograde',
    dieselMultiGrade: 'diesel-multigrade',
    dieselFullySynthetic: 'diesel-fully-synthetic',
    gasolineMonoGrade: 'gasoline-monograde',
    gasolineMultiGrade: 'gasoline-multigrade',
    gasolineFullySynthetic: 'gasoline-fully-synthetic',
};
  
export default OilChangeType;
  